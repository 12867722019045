import React from 'react';
import { Spinner } from 'react-bootstrap';

const FormButton = ({ isSubmitting, text = "", ...rest }) => {
  return (<>
    <button disabled={isSubmitting} {...rest}>
      {isSubmitting &&
        <span className="loading d-inline-flex align-items-center justify-content-center me-2">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </span>
      }
      <span className="text">{text}</span>
    </button>
  </>)
}
export default FormButton;