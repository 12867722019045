import React, { useEffect, useState } from 'react';
import MatchService from '../../services/api/match.service';
import LeftSidebar from '../common/sidebar/LeftSidebar';
import RightSidebar from '../common/sidebar/RightSidebar';
import { connect } from 'react-redux';
import { setStports } from '../../redux/slices/match';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MobileSidebar from '../common/sidebar/MobileSidebar';
import { Outlet } from 'react-router-dom';

const SiteLayout = ({ setStports }) => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      MatchService.getSportsList().then(
        (data) => {
          if (data) {
            // console.log('getSportsList', data);
            setStports(data);
          }
          setIsLoaded(true);
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          setIsLoaded(true);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);


  return (<>
    <div className={`site-layout`}>
      <Header />
      <MobileSidebar />
      <div className="page-area">

        <section className="game-content-area">
          <div className="row">

            <div className="col-lg-2 left-sidebar d-xs-none">
              <LeftSidebar />
            </div>
            <div className="col-lg-7 game-area">
              {isLoaded &&
                <Outlet />
              }
            </div>
            <div className="col-lg-3 right-sidebar">
              <RightSidebar />
            </div>

          </div>
        </section>

      </div>
      <Footer />
    </div>
  </>)
}

export default connect(null, { setStports })(SiteLayout);