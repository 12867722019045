import { faDice, faFootball, faBaseball, faBars, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useForm } from "react-hook-form";
import logo from '../../../assets/images/logo.png';
import { appName, clientId, clientSecret } from '../../../config/config';
import { switchBetSlipMode, switchMobileMenuMode } from '../../../redux/slices/common';
import AuthService from '../../../services/api/auth.service';
import { login, loadedLoggedIn, logout, setUser } from '../../../redux/slices/auth';
import { setMessage } from '../../../redux/slices/message';
import UserService from '../../../services/api/user.service';
import Dropdown from 'react-bootstrap/Dropdown';
import { Spinner } from 'react-bootstrap';
import HeaderNotice from '../notice/HeaderNotice';

const Header = ({ switchBetSlipMode, switchMobileMenuMode, setMessage, login, loadedLoggedIn, refreshToken, logout, setUser, updatedCoinsChange }) => {

  const location = useLocation();
  // console.log(location);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedCoins, setUpdatedCoins] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const [userData, setUserData] = useState(null);
  const [userDropdownState, setUserDropdownState] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshToken) {
        UserService.getProfile().then(
          (data) => {
            if (data) {
              // console.log('getProfile', data);
              setUser(data);
              setUserData(data);
              loadedLoggedIn({
                loaded: true,
                loggedIn: true
              });
            }
            return Promise.resolve();
          },
          (error) => {
            // console.log(error?.response?.data?.message);
            logoutCookie();
            return Promise.reject();
          }
        );
      } else {
        setUser(null);
        setUserData(null);
      }
    }
    return () => {
      mounted = false;
    }
  }, [refreshToken]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setUserDropdownState(false);
    }
    return () => {
      mounted = false;
    }
  }, [location]);

  useEffect(() => {
    let mounted = true;
    if (mounted && updatedCoinsChange) {
      UserService.getCoins().then(
        (data) => {
          if (data) {
            // console.log('getCoins', data);
            setUpdatedCoins(data.current_balance);
          }
          return Promise.resolve();
        },
        (error) => {
          console.log(error?.response);
          return Promise.reject();
        }
      );
    }
    return () => {
      mounted = false;
    }
  }, [updatedCoinsChange]);


  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append('username', data.username ? data.username : '');
    formData.append('password', data.password ? data.password : '');
    formData.append('client_id', clientId);
    formData.append('client_secret', clientSecret);
    formData.append('grant_type', "password");
    setIsSubmitting(true);
    AuthService.signIn(formData).then(
      (data) => {
        if (data) {
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + (data.expires_in * 1000);
          now.setTime(expireTime);
          // console.log('now', now);
          setCookie('access_token', data.access_token, { path: '/', expires: now });
          setCookie('refresh_token', data.refresh_token, { path: '/' });
          login({
            loaded: true,
            loggedIn: true,
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
          });
          setMessage({
            type: "success",
            title: "Sign In Successfully.",
          });
        }
        setIsSubmitting(false);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.message);
        setMessage({
          type: "error",
          title: error?.response?.data?.message,
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        return Promise.reject();
      }
    );
  };

  const logoutCookie = () => {
    console.log("here");
    removeCookie('access_token', { path: '/' });
    removeCookie('refresh_token', { path: '/' });
    setUser(null);
    setUserData(null);
    logout();
  }

  const handleLogout = () => {
    logoutCookie();
    setUserDropdownState(false);
    setMessage({
      type: "success",
      title: "Logout Successfully.",
    });
  }

  const handleUserDropdown = (state) => {
    setUserDropdownState(state);
  }


  return (<>
    <section className="header-area fixed">
      <HeaderNotice />
      <div className="header-bottom-border"></div>

      <div className="container-fluid header-bottom-area">
        <div className="row align-items-center justify-content-between">

          <div className="col-3 col-sm-2">
            <div className="logo">
              <Link to='/'>
                <img src={logo} alt={appName} />
                {/* XYZ */}
              </Link>
            </div>
          </div>

          <div className="col-sm-7 d-xs-none">
            <div className="main-menu">
              <ul id="main-menu">
                <li className={`${location && location.pathname == "/in-play" ? "active" : ""}`}><Link to="/in-play">In-Play</Link></li>
                <li className={`${location && location.pathname == "/pre-match" ? "active" : ""}`}><Link to="/pre-match">Advance Match</Link></li>
                <li><Link to="/">TV Games</Link></li>
                <li><Link to="/">Promo</Link></li>
                {/* <li><Link to="/profile">My Profile</Link></li>
                  <li><Link to="/sign-in">Sign In</Link></li>
                  <li><Link to="/register">Register</Link></li> */}
              </ul>
            </div>
          </div>

          <div className="col-9 col-sm-6 col-lg-3">
            {userData ?
              <div className="user-nav dark text-end">
                <Dropdown onToggle={(e) => handleUserDropdown(e)} show={userDropdownState} className="d-xs-none">
                  <Dropdown.Toggle id="dropdown-button-dark-example1" caret="false">
                    <div className="d-flex align-items-center">
                      {/* <div className="user-icon">
                        <FontAwesomeIcon icon={faUserCircle} />
                      </div> */}
                      <div className="user-info text-end">
                        <h3 className="text-capitalize mb-1">Hi, {userData.username}</h3>
                        <p className="mb-0">
                          <span className="balance-credit fw-bold">Balance: <span className="text-blue">{updatedCoins != null ? updatedCoins : userData.current_balance}</span></span>
                          {/* <span className="balance-credit ms-2 fw-bold">Bonus: <span className="text-blue">{userData.bonus_credit}</span></span> */}
                        </p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Link className="d-block text-white px-3 pt-2" to="/profile/view-profile">My Profile</Link>
                    <Link className="d-block text-white px-3 pt-2 pb-1" to="/profile/bet-history">Bet History</Link>

                    <button type="button" className="d-block text-white px-2 w-100 border-0 " onClick={() => handleLogout()}>Logout</button>
                  </Dropdown.Menu>
                </Dropdown>
                <button className="mobile-header-user-button bg-transparent border-0 d-none d-xs-flex justify-content-end ms-auto text-white" onClick={() => switchMobileMenuMode(true)}>
                  <div className="d-flex align-items-center">
                    {/* <div className="user-icon">
                        <FontAwesomeIcon icon={faUserCircle} />
                      </div> */}
                    <div className="user-info text-end">
                      <h3 className="text-capitalize mb-1">Hi, {userData.username}</h3>
                      <p className="mb-0">
                        <span className="balance-credit fw-bold">Balance: <span className="text-blue">{updatedCoins != null ? updatedCoins : userData.current_balance}</span></span>
                        {/* <span className="balance-credit ms-2 fw-bold">Bonus: <span className="text-blue">{userData.bonus_credit}</span></span> */}
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              :
              <div className="user-nav dark">
                <div className="user-nav-group">
                  <div className="d-flex right">
                    <form className="d-flex justify-content-between w-100" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                      <div className="me-1">
                        <input type="text" placeholder="Username" className=""
                          {...register("username", {
                            required: "Username required"
                          })}
                        />
                      </div>
                      <div className="me-1">
                        <input type="password" placeholder="Password" className=""
                          {...register("password", {
                            required: "Password required"
                          })}
                        />
                      </div>
                      <button type="submit" className="login-btn" disabled={isSubmitting}>
                        {isSubmitting ?
                          <span className="loading d-inline-flex align-items-center justify-content-center">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </span>
                          :
                          <span>OK</span>
                        }
                      </button>
                    </form>
                  </div>
                  <div className="d-flex justify-content-between register-part">
                    <Link to="/register" className="register-link">Register</Link>
                    <Link to="/forgot-password">Forgot your password?</Link>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
      </div>

    </section>

    <div className="mobile-bottom-navigation d-none d-xs-block">
      <ul>
        <li className={`${location && location.pathname == "/in-play" ? "active" : ""}`}>
          <Link to="/in-play">
            <span className="icon">
              <FontAwesomeIcon icon={faFootball} />
            </span>
            <span className="text">In-Play</span>
          </Link>
        </li>
        <li className={`${location && location.pathname == "/pre-match" ? "active" : ""}`}>
          <Link to="/pre-match">
            <span className="icon">
              <FontAwesomeIcon icon={faBaseball} />
            </span>
            <span className="text">Advance Match</span>
          </Link>
        </li>
        <li>
          <button type="button" onClick={() => switchBetSlipMode(true)}>
            <span className="icon">
              <FontAwesomeIcon icon={faDice} />
            </span>
            <span className="text">Betslip</span>
          </button>
        </li>
        {userData ?
          <li>
            <button type="button" onClick={() => switchMobileMenuMode(true)}>
              <span className="icon">
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span className="text">Menu</span>
            </button>
          </li>
          :
          <li className={`${location && location.pathname == "/sign-in" ? "active" : ""}`}>
            <Link to="/sign-in">
              <span className="icon">
                <FontAwesomeIcon icon={faUserAlt} />
              </span>
              <span className="text">Sign In</span>
            </Link>
          </li>
        }
      </ul>
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  refreshToken: state.auth.auth.refreshToken,
  updatedCoinsChange: state.common.updatedCoinsChange
});

export default connect(mapStateToProps, { switchBetSlipMode, switchMobileMenuMode, setMessage, login, loadedLoggedIn, logout, setUser })(Header);
