import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import Pagination from '../../../components/common/pagination/Pagination';
import UserService from '../../../services/api/user.service';

const MyFollowers = () => {

  const [followers, setFollowers] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getList(1);
    }

    return () => {
      mounted = false;
    }
  }, []);

  const getList = (page) => {
    setIsLoaded(false);
    UserService.getFollowers().then(
      (data) => {
        if (data) {
          // console.log('getfollowerHistory', data);
          setFollowers(data.data);
          setCurrentPage(data.current_page);
          setLastPage(data.last_page);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  }

  const handlePage = (page) => {
    // console.log("page", page);
    getList(page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (<>
    <div className="tab-pane" id="nav-my-followers" role="tabpanel">
      <div className="admin-content-header-new">My Followers</div>
      <div className="admin-content-scroll-wrapper">
        {isLoaded ?
          <div className="row admin-profile-table justify-content-center">
            <div className="col-lg-12">
              {followers && followers.length > 0 &&
                <div className="row box-card-row gx-2 d-none d-xs-flex">
                  {followers.map((follower, index) => {
                    return (
                      <div className="col-lg-4 mb-3" key={index}>
                        <div className="card box-card h-100">
                          <h5 className="card-header bg-dark text-white">{follower.username}</h5>
                          <div className="card-body">
                            <div className="row gx-2">
                              <div className="col-12">
                                <div className="d-block mt-1 mb-1">Phone:
                                  <strong>{follower.phone}</strong>
                                </div>
                                <div className="d-block mt-1 mb-1">Join Date:
                                  <strong>{follower.join_date}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }

              <div className="row">
                <div className="col-lg-12">
                  {followers && followers.length > 0 &&
                    <div className="card-table d-xs-none">
                      <table>
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Phone</th>
                            <th>Join Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {followers.map((follower, index) => {
                            return (
                              <tr key={index}>
                                <td><strong>{follower.username}</strong></td>
                                <td>{follower.phone}</td>
                                <td>{follower.join_date}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>
              {!(followers && followers.length > 0) &&
                <div className="row box-card-row mh-70v align-items-center">
                  <div className="col-lg-12 text-center">
                    <h5 className="mb-0 text-white-ts-80">No follower is Available</h5>
                  </div>
                </div>
              }

              {followers && followers.length > 0 &&
                <Pagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  handlePage={handlePage}
                />
              }
            </div>
          </div>
          :
          <LoadingSpinner />
        }
      </div>
    </div>
  </>)
}
export default MyFollowers;