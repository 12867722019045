import React, { useEffect } from 'react';
import UserLeftSidebar from '../common/sidebar/UserLeftSidebar';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MobileSidebar from '../common/sidebar/MobileSidebar';
import BetSlip from '../common/bet/BetSlip';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const UserLayout = ({ refreshToken }) => {

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!refreshToken) {
        navigate('/');
      }
    }
    return () => {

    }
  }, [refreshToken]);

  return (<>
    <div className={`site-layout`}>
      <Header />
      <MobileSidebar />
      <section className="page-area admin-template-area">
        <div className="row">
          <div className="col-lg-2 left-sidebar d-xs-none">
            <UserLeftSidebar />
          </div>
          <div className="col-lg-10 admin-content">
            <Outlet />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  refreshToken: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, null)(UserLayout);