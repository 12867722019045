import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatedDate } from '../../../helper/date';
import BetList from '../bet/BetList';

import iconLive from '../../../assets/images/Icon_live_red.svg';
import NotFoundMessage from '../404/NotFoundMessage';

const MatchList = ({ matchList }) => {
    // console.log("matchList", matchList);
    const [matchListData, setMatchListData] = useState(matchList);

    useEffect(() => {
      let mounted = true;
      if(mounted) {
        setMatchListData(matchList);
      }
    
      return () => {
        mounted = false;
      }
    }, [matchList]);

    return (<>
        <div className="live-in-play">

            <div className="tab-content" id="live-in-play-tab-content">
                <div className="tab-pane-1">

                    {matchListData && matchListData.length > 0 ?
                        <div>
                            {matchListData.map((match, index) => {
                                if (match) {
                                    return (
                                        <div className="single-match-result" key={index}>

                                            <div className="single-match-result-header">
                                                <Link to={"/match/" + match.id} className="left-side">
                                                    {match.tournaments && match.tournaments.categories && match.tournaments.categories.category_icon_url &&
                                                        <span className="icons">
                                                            <img src={match.tournaments.categories.category_icon_url} className="header-category-icon" alt="" />
                                                        </span>
                                                    }
                                                    <span className="match-infos">
                                                        <span className="top-side">
                                                            <span>{match.match_title}</span>
                                                            {match.status == 'LIVE' &&
                                                                <img src={iconLive} className="header-live-icon" alt="" />
                                                            }
                                                        </span>
                                                        <span className="bottom-side">
                                                            <span className="match-team-name">{match.first_team} <i className="vs">vs</i> {match.second_team}</span>
                                                        </span>
                                                    </span>
                                                </Link>

                                                <div className="right-side">
                                                    <div className="top-side">
                                                        <p>{match.live_updates}</p>
                                                    </div>
                                                    <div className="bottom-side">
                                                        <span className="current-time">{formatedDate(match.match_datetime, "DD-MMM-YY, hh:mm A")}</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <BetList betList={match.bets} />

                                        </div>

                                    )
                                }
                            })}

                        </div>
                        :
                        <NotFoundMessage message="No Match Is Available Now!" />
                    }
                </div>

            </div>

        </div>
    </>)
}

export default MatchList;