import React, { useEffect, useState } from 'react';
import { faHandshake, faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import AuthService from '../../../services/api/auth.service';
import UserService from '../../../services/api/user.service';
import { setMessage } from '../../../redux/slices/message';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import FormButton from '../../../components/common/button/FormButton';
import MessageBox from '../../../components/common/message/MessageBox';
import AlertDismissible from '../../../components/common/message/AlertDismissible';

const UpdateClub = ({ user, setMessage }) => {

  const [clubs, setClubs] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Your request has been created.");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      AuthService.getClubList().then(
        (data) => {
          if (data) {
            // console.log('getPreMatchList', data);
            setClubs(data);
          }
          setIsLoaded(true);
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          setIsLoaded(true);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);

    const formData = new FormData();
    formData.append('club', data.club ? data.club : '');
    formData.append('password', data.password ? data.password : '');
    setIsSubmitting(true);
    UserService.updateClub(formData).then(
      (data) => {
        if (data) {
          // console.log('updateClub', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data?.message);
          // setMessage({
          //   type: "success",
          //   title: data?.message,
          // });
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
        return Promise.reject();
      }
    );
  };

  return (<>
    <div className="tab-pane" id="nav-change-club" role="tabpanel">
      <div className="admin-content-header-new">Update Club</div>
      <div className="admin-content-scroll-wrapper">
        {isSubmitSuccess ?
          <MessageBox
            message={successMessage}
          />
          :
          <>
            {isLoaded ?
              <div className="row admin-profile-update-form">

                <div className="col-lg-6">
                  <AlertDismissible
                    message={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                  {user &&
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                      <div className="form-group">
                        <label htmlFor="adf-club">Club</label>
                        {clubs && clubs.length > 0 &&
                          <div className="form-group-input-wrapper">
                            <span className="input-group-icon">
                              <FontAwesomeIcon icon={faHandshake} />
                            </span>
                            <select
                              className="form-control"
                              {...register("club")}
                              defaultValue={user ? user.club : clubs[0].id}
                            >
                              {clubs.map((club, index) => {
                                return (
                                  <option value={club.id} key={index}>{club.name}</option>
                                )
                              })}
                            </select>
                          </div>
                        }
                      </div>

                      <div className="form-group">
                        <label htmlFor="adf-current-password-for-club">Current Password*</label>
                        <div className="form-group-input-wrapper">
                          <span className="input-group-icon">
                            <FontAwesomeIcon icon={faKey} />
                          </span>
                          <input
                            className="form-control"
                            type={passwordMode.password}
                            placeholder="Current Password"
                            {...register("password", {
                              required: "Password required",
                            })}
                          />
                          <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                            <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
                          </button>
                        </div>
                        {errors.password && <span className="input-validation" style={{ right: "40px" }}>{errors.password.message}</span>}
                      </div>

                      <div className="row gx-0">
                        <div className="col-lg-8">
                          <FormButton
                            type="submit"
                            text="Update Club"
                            className="admin-profile-update-form-submit d-inline-flex align-items-center justify-content-center"
                            name="admin-update-club"
                            isSubmitting={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  }
                </div>
              </div>
              :
              <LoadingSpinner />
            }
            {isSubmitting &&
              <LoadingSpinner overlay={true} />
            }
          </>
        }
      </div>
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user
});
export default connect(mapStateToProps, { setMessage })(UpdateClub);