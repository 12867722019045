import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ overlay = false }) => {
  return (<>
    <div className={`loading-spinner-area mh-50vh d-flex align-items-center justify-content-center text-center bg-white overflow-hidden ${overlay ? "overlay position-absolute start-0 top-0 w-100 h-100" : "rounded-2"}`}>
      <div className="loading-spinner-content">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />
      </div>
    </div>
  </>)
}
export default LoadingSpinner;