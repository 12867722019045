import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { emailPattern } from '../../../constant/pattern';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../services/api/auth.service';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../../redux/slices/message';
import { Spinner } from 'react-bootstrap';

const RegisterForm = ({ setMessage }) => {

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setCountries] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      AuthService.getCountryList().then(
        (data) => {
          if (data) {
            // console.log('getPreMatchList', data);
            setCountries(data);
            if (data && data.length > 0) {
              setPhoneCode(data[0].phone_code);
            }
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          return Promise.reject();
        }
      );

      AuthService.getClubList().then(
        (data) => {
          if (data) {
            // console.log('getPreMatchList', data);
            setClubs(data);
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const handleSelect = (e) => {
    // console.log(e.target.value);
    let id = e.target.value;
    let findCountry = countries.find(value => value.id == id);
    if (findCountry) {
      setPhoneCode(findCountry.phone_code);
    }
  };

  const onSubmit = (data) => {
    // console.log("data", data);
    const formData = new FormData();
    formData.append('username', data.username ? data.username : '');
    formData.append('email', data.email ? data.email : '');
    formData.append('password', data.password ? data.password : '');
    formData.append('password_confirm', data.password_confirm ? data.password_confirm : '');
    formData.append('phone', data.phone ? data.phone : '');
    formData.append('country', data.country ? data.country : '');
    formData.append('club', data.club ? data.club : '');
    formData.append('sponsorname', data.sponsorname ? data.sponsorname : '');
    setIsSubmitting(true);
    AuthService.register(formData).then(
      (data) => {
        if (data) {
          // console.log('register', data);
          setMessage({
            type: "success",
            title: data,
          });
          setTimeout(() => {
            navigate('/sign-in');
          }, 300);
        }
        setIsSubmitting(false);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error.response.data.errors.message);
        setMessage({
          type: "error",
          title: error?.response?.data?.errors?.message,
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        return Promise.reject();
      }
    );
  };

  return (<>

    <form className="rs-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row form-inputs-wrapper">
        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <input
              className="form-control"
              type="text"
              placeholder="Username"
              {...register("username", {
                required: "Username required"
              })}
            />
            {errors.username && <span className="input-validation">{errors.username.message}</span>}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <input
              className="form-control"
              type="email"
              placeholder="Email"
              {...register("email", {
                required: "Email required",
                pattern: {
                  value: emailPattern,
                  message: "Enter a valid email",
                }
              })}
            />
            {errors.email && <span className="input-validation">{errors.email.message}</span>}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <div className="form-group-input-password-wrapper position-relative">
              <input
                className="form-control"
                type={passwordMode.password}
                placeholder="Password"
                {...register("password", {
                  required: "Password required",
                  minLength: {
                    value: 8,
                    message: "Password must be minimum 8 characters",
                  }
                })}
              />
              <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
              </button>
            </div>
            {errors.password && <span className="input-validation">{errors.password.message}</span>}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <div className="form-group-input-password-wrapper position-relative">
              <input
                className="form-control"
                type={passwordMode.confirmPassword}
                placeholder="Confirm Password"
                {...register("password_confirm", {
                  required: "Confirm Password required",
                  validate: (value) => value === watch('password') || "Password not match"
                })}
              />
              <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("confirmPassword")}>
                <FontAwesomeIcon icon={passwordMode.confirmPassword == "password" ? faEye : faEyeSlash} />
              </button>
            </div>
            {errors.password_confirm && <span className="input-validation">{errors.password_confirm.message}</span>}
          </div>
        </div>

        {countries && countries.length > 0 &&
          <div className="col-lg-6">
            <div className="form-group-input-wrapper">
              <select
                className="form-control"
                {...register("country")}
                defaultValue={countries[0].id}
                onChange={(e) => handleSelect(e)}
              >
                {countries.map((country, index) => {
                  return (
                    <option value={country.id} key={index}>{country.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        }

        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <div className="form-group-phone-wrapper">
              {phoneCode &&
                <span className="phone-code-label">+{phoneCode}</span>
              }
              <input
                className="form-control"
                type="text" placeholder="Phone"
                {...register("phone", {
                  required: "Phone required",
                  minLength: {
                    value: 11,
                    message: "Phone number must be 11 digit"
                  },
                  maxLength: {
                    value: 11,
                    message: "Phone number must be 11 digit"
                  },
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: 'Please enter a number',
                  },
                })}
              />
            </div>
            {errors.phone && <span className="input-validation">{errors.phone.message}</span>}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group-input-wrapper">
            <input
              className="form-control"
              type="text" placeholder="Sponsorname (optional)"
              {...register("sponsorname")}
            />
          </div>
        </div>
        {clubs && clubs.length > 0 &&
          <div className="col-lg-6">
            <div className="form-group-input-wrapper">
              <select
                className="form-control"
                {...register("club")}
                defaultValue={clubs[0].id}
              >
                {clubs.map((club, index) => {
                  return (
                    <option value={club.id} key={index}>{club.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        }

      </div>

      <div className="rs-other-info termes-policy">
        <span className="text-center">
          By clicking "Register" I agree to NBS <a href="/" target="_blank" rel="noopener noreferrer">Terms of Service</a>. and <a href="/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </span>
      </div>

      <div className="rs-submit">
        <button type="submit" name="fgr_reg-submit" disabled={isSubmitting}>
          {isSubmitting ?
            <span className="loading d-inline-flex align-items-center justify-content-center">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </span>
            :
            <span>Register</span>
          }
        </button>
      </div>

    </form>
  </>)
}

export default connect(null, { setMessage })(RegisterForm);