import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const MessageBox = ({ message = "",  }) => {
  return (<>
    <div className="message-box-area mh-50vh d-flex align-items-center justify-content-center text-center bg-white rounded-2 overflow-hidden py-5 my-3">
      <div className="message-box-content d-flex flex-column align-items-center justify-content-center">
        <div className="icon">
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <h3>Success</h3>
        <div className="text">{message}</div>
      </div>
    </div>
  </>)
}
export default MessageBox;