import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './slices/common';
import matchReducer from './slices/match';
import messageReducer from './slices/message';
import authReducer from './slices/auth';
import betReducer from './slices/bet';

export default configureStore({
  reducer: {
    common: commonReducer,
    match: matchReducer,
    message: messageReducer,
    auth: authReducer,
    bet: betReducer,
  },
});