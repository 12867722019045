import React, { useEffect, useState } from 'react';
import heroBannar from '../../assets/images/hero_Bannar.png';
import chinaFlag from '../../assets/images/flag.png';
import BetList from '../../components/common/bet/BetList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import MatchService from '../../services/api/match.service';
import LoadingSpinner from '../../components/common/loader/LoadingSpinner';

const MatchDetails = () => {

  const params = useParams();
  const navigate = useNavigate();
  // console.log("params", params);

  const [isLoaded, setIsLoaded] = useState(false);
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchBets, setMatchBets] = useState([]);
  const [matchBetsFiltered, setMatchBetsFiltered] = useState([]);
  const [matchBetsSelectList, setMatchBetsSelectList] = useState([]);
  const [timeRemain, setTimeRemain] = useState(false);
  const [timePassed, setTimePassed] = useState(false);
  const [calculatedTime, setCalculatedTime] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      MatchService.getMatchDetailsById(params.id).then(
        (data) => {
          if (data) {
            // console.log('getLiveMatchList', data);
            setMatchDetails(data);
            if (data.bets && data.bets.length > 0) {
              setMatchBets(data.bets);
              setMatchBetsFiltered(data.bets);
              let betNames = [];
              data.bets.map((bet) => {
                betNames.push(bet.bet_title);
              });
              setMatchBetsSelectList(betNames);
            }
            calculateTime(data.match_datetime);
          }
          setIsLoaded(true);
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          setIsLoaded(true);
          return Promise.reject();
        }
      );

    }

    return () => {
      mounted = false;
    }
  }, [params]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('match-bet-update')
        .listen('MatchEvents', (e) => {
          if (matchDetails && matchDetails.id === e.match_id) {
            setMatchBets(e.data);
          }
        });

      return () => {
        window.Echo.leaveChannel("match-bet-update");
      };
    }
  }, [matchDetails]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('bet-ratio-change')
        .listen('MatchEvents', (e) => {
          if (matchBets && matchBets.length > 0) {
            let matchBetsDummy = [...matchBets];
            matchBetsDummy.map((bet) => {
              if (bet.id == e.betId) {
                bet.bet_events.map((betEvent, index) => {
                  const eventData = e.data;
                  const item = eventData.find(item => item.id === betEvent.id);
                  if (item) {
                    if (betEvent.bet_ratio < item.bet_ratio) {
                      bet.bet_events[index].indicator = 'up';
                    } else if (betEvent.bet_ratio > item.bet_ratio) {
                      bet.bet_events[index].indicator = 'down';
                    }
                    bet.bet_events[index].bet_ratio = item.bet_ratio;
                  }
                });
              }
            });
            setMatchBets(matchBetsDummy);
          }
        });

      return () => {
        window.Echo.leaveChannel("bet-ratio-change");
      };
    }
  }, [matchBets]);

  useEffect(() => {
    let interval;
    if (matchDetails && matchDetails.match_datetime) {
      interval = setInterval(() => {
        calculateTime(matchDetails.match_datetime);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [matchDetails]);

  const calculateTime = (date) => {
    const match_date = +new Date(date);
    const cur_date = +new Date();
    let diffTime;
    if (match_date > cur_date) {
      diffTime = Math.abs(match_date - cur_date);
      setTimePassed(false);
      setTimeRemain(true);
    } else {
      diffTime = Math.abs(cur_date - match_date);
      setTimePassed(true);
      setTimeRemain(false);
    }

    if (diffTime) {
      const hoursDiff = Math.ceil((diffTime / (1000 * 60 * 60)) % 24);
      const minutesDiff = Math.ceil((diffTime / (1000 * 60)) % 60);
      const secondsDiff = Math.ceil((diffTime / 1000) % 60);
      // console.log(hoursDiff, minutesDiff, secondsDiff);

      const timeRemainData = {
        hours: hoursDiff,
        minutes: minutesDiff,
        seconds: secondsDiff
      }
      setCalculatedTime(timeRemainData);
      // console.log(timeRemainData);

      return timeRemainData;
    }

    return null;
  }

  const handleSelect = (e) => {
    // console.log(e.target.value);
    let betName = e.target.value;
    if (matchBets && matchBets.length > 0 && betName) {
      let betList = [];
      matchBets.map((bet) => {
        if (bet.bet_title == betName) {
          betList.push(bet);
        }
      });
      setMatchBetsFiltered(betList);
    } else {
      setMatchBetsFiltered(matchBets);
    }
  };

  const handleSearch = (e) => {
    // console.log(e.target.value);
    let betName = e.target.value;
    if (matchBets && matchBets.length > 0 && betName) {
      let betList = [];
      matchBets.map((bet) => {
        if (bet.bet_title.toLowerCase().includes(betName.toLowerCase())) {
          betList.push(bet);
        }
      });
      setMatchBetsFiltered(betList);
    } else {
      setMatchBetsFiltered(matchBets);
    }
  };

  // const timePassed = (date) => {
  //   const match_date = +new Date();
  //   const cur_date = +new Date(date);

  //   if (match_date > cur_date) {
  //     const diffTime = Math.abs(match_date - cur_date);

  //     const hoursDiff = Math.ceil((diffTime / (1000 * 60 * 60)) % 24);
  //     const minutesDiff = Math.ceil((diffTime / (1000 * 60)) % 60);
  //     const secondsDiff = Math.ceil((diffTime / 1000) % 60);
  //     // console.log(hoursDiff, minutesDiff, secondsDiff);

  //     const timeRemainData = {
  //       hours: hoursDiff,
  //       minutes: minutesDiff,
  //       seconds: secondsDiff
  //     }

  //     // console.log(timeRemainData);

  //     return timeRemainData;
  //   }

  //   return null;
  // }

  const parseMatchTime = (date) => {
    // console.log("date", date);
    let matchDate = new Date(date);
    // console.log("matchDate", matchDate);

    // timeRemain(date);
    return matchDate.getHours() + ":" + matchDate.getMinutes();
  }

  if (isLoaded && !matchDetails) {
    navigate('/404');
  }

  return (<>
    {isLoaded && matchDetails ?
      <div className="match-details-page-area h-100">
        <div className="match-details-breadcumb-area">
          <ul className="breadcumb">
            {matchDetails.tournaments &&
              <li><a href="/">{matchDetails.tournaments.tournament_name}</a></li>
            }
            <li className="active">{matchDetails.match_title}</li>
          </ul>
        </div>

        <div className="match-details-body">
          <div className="match-details-banner" style={{ backgroundImage: "url(" + heroBannar + ")" }}>

            <div className="match-location-date">
              {timeRemain &&
                <span className="date">Match Will Start Within</span>
              }
              {timePassed &&
                <span className="date">Match Started From</span>
              }
            </div>
            {timeRemain && calculatedTime &&
              <div className="match-countdown-timer">
                <span className="icon"><i className="fas fa-clock"></i></span>
                <span className="timer">
                  <span> {calculatedTime.hours} </span><sup>Hrs</sup>
                  <span> {calculatedTime.minutes} </span><sup>Min</sup>
                  <span>{calculatedTime.seconds}</span><sup>Sec</sup>
                </span>
              </div>
            }
            {timePassed && calculatedTime &&
              <div className="match-countdown-timer">
                <span className="icon"><i className="fas fa-clock"></i></span>
                <span className="timer">
                  <span> {calculatedTime.hours} </span><sup>Hrs</sup>
                  <span> {calculatedTime.minutes} </span><sup>Min</sup>
                  <span>{calculatedTime.seconds}</span><sup>Sec</sup>
                </span>
              </div>
            }

            <div className="match-team-list">
              <div className="team-name-flag">
                <span className="flag"><img src={chinaFlag} alt="" /></span>
                <span className="name">{matchDetails.first_team}</span>
              </div>
              <div className="match-start-time">
                <span className="text">Start Time</span>
                <span className="time">{parseMatchTime(matchDetails.match_datetime)}</span>
              </div>
              <div className="team-name-flag">
                <span className="flag"><img src={chinaFlag} alt="" /></span>
                <span className="name">{matchDetails.second_team}</span>
              </div>
            </div>

          </div>


          <div className="game-options-area">
            {matchBetsSelectList && matchBetsSelectList.length > 0 &&
              <div className="game-options-left-side h-100">
                <div className="game-option-list-search w-100 h-100">
                  <div className="game-option-list w-100 h-100">
                    <form className="game-option-list-form w-100 h-100">
                      <select className="form-control h-100"
                        onChange={(e) => handleSelect(e)}
                      >
                        {matchBetsSelectList.map((betName, index) => {
                          return (
                            <option value={betName} key={index}>{betName}</option>
                          )
                        })}
                      </select>
                    </form>
                  </div>
                </div>
              </div>
            }
            <div className="game-options-right-side h-100">
              <div className="game-option-list-search w-100 h-100">
                <div className="game-search-area w-100 h-100">
                  <form className="game-search-form w-100 h-100">
                    <div className="form-group-input-wrapper w-100 h-100">
                      <input className="form-control" id="game_search" type="text" placeholder="Search" name="game_search" onChange={(e) => handleSearch(e)} />
                    </div>
                    <div className="search-submit">
                      <button type="submit" name="game_search-submit">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div >

          <div className="match-details-game-box">
            <div className="match-details-game-header">
              <span className="text">{matchDetails.match_title} : {matchDetails.first_team} vs {matchDetails.second_team}</span>
              {/* <span className="statistics">
                <img src={graphIcon} alt="" />
              </span> */}
            </div>

            <div className="match-details-game-tab-area">

              <div className="tab-content match-details-game-tab">

                <div className="tab-pane fade show active" id="match-details-game-tab-2" role="tabpanel">


                  <div className="row">
                    <div className="col-lg-12">
                      <BetList betList={matchBetsFiltered} />
                    </div>

                  </div>


                </div>

              </div>
            </div>
          </div>


          <div className="game-indicator-message">The time display shown within live betting serves as an indicator. The company takes no responsibility for the correctness and currentness of the displayed information like score or time.</div>
        </div>

      </div>
      :
      <LoadingSpinner />
    }
  </>)
}
export default MatchDetails;