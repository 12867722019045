import React from 'react';

import BetSlip from '../bet/BetSlip';
import UpcomingMatches from '../match/UpcomingMatches';

const RightSidebar = () => {
    return (<>
        <BetSlip />
        <UpcomingMatches />
    </>)
}

export default RightSidebar;
