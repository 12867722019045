import { faFacebook, faTwitter, faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faMobileAlt, faPhone, faDollar, faChartBar, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logo1 from '../../../assets/images/logo-1.png';
import logo2 from '../../../assets/images/logo-2.png';
import logo3 from '../../../assets/images/logo-3.png';
import logo4 from '../../../assets/images/logo-4.png';
import logo5 from '../../../assets/images/logo-5.png';
import logo6 from '../../../assets/images/logo-6.png';
import logo7 from '../../../assets/images/logo-7.png';
import logo8 from '../../../assets/images/logo-8.png';
import appStore from '../../../assets/images/app-store.png';
import android from '../../../assets/images/android.png';
import footerBg from '../../../assets/images/footer-bg.jpg';
import copyrightLogo1 from '../../../assets/images/copyright-logo-1-white.png';
import copyrightLogo2 from '../../../assets/images/copyright-logo-2-white.png';
import copyrightLogo3 from '../../../assets/images/copyright-logo-3-white.png';


const Footer = () => {
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const toRef = setTimeout(() => {
        setShowComponent(true);
        clearTimeout(toRef);
      }, 2000);
    }

    return () => {
      mounted = false;
    }
  }, []);

  return (<>
    {showComponent &&
      <div className="footer-bg-area">
        <section className="footer-area">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-3">
                <div className="footer-links">
                  <h3>About</h3>
                  <ul>
                    <li><Link to="/">About Us</Link></li>
                    <li><Link to="/">Contact Us</Link></li>
                    <li><Link to="/">Terms and Conditions</Link></li>
                    <li><Link to="/">Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="footer-links">
                  <h3>Support</h3>
                  <ul>
                    <li><Link to="/">FAQs</Link></li>
                    <li><Link to="/">Sport Betting Rules</Link></li>
                    <li><Link to="/">Match Center</Link></li>
                    <li><Link to="/">Counter Offer</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="mobile-apps">
                  <h3>Mobile Apps</h3>
                  <ul>
                    <li>
                      <a target="_blank" to="/" rel="noopener noreferrer">
                        <img src={android} alt="" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" to="/" rel="noopener noreferrer">
                        <img src={appStore} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="follow-us mt-4">
                  <h3>Follow Us</h3>
                  <ul>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="logo-list logo-list-stacked">
                  <ul>
                    <li><img src={logo1} alt="" /></li>
                    <li><img src={logo2} alt="" /></li>
                    <li><img src={logo3} alt="" /></li>
                    <li><img src={logo4} alt="" /></li>
                    <li><img src={logo5} alt="" /></li>
                    <li><img src={logo6} alt="" /></li>
                    <li><img src={logo7} alt="" /></li>
                    <li><img src={logo8} alt="" /></li>
                  </ul>
                </div>
                <div className="row align-items-center justify-content-between mt-3">

                  <div className="col-9 col-lg-7 text-center">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="copyright-logo">
                          <img src={copyrightLogo1} alt="" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="copyright-logo">
                          <img src={copyrightLogo2} alt="" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="copyright-logo">
                          <img src={copyrightLogo3} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="col-3 col-lg-5 text-center">
                    <div className="eighteen-plus d-flex justify-content-center">
                      <h2>18+</h2>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="copyright-area">
          <div className="container-fluid">

            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <div className="copyright-text">
                  <p className="mb-0">Owned by  MC International Limited (reg.number HE3552) with a registered office located at Telamonos 30 street, Springfield Court, Block D, Office 303, 33 larnaca , Cyprus as a Billing Agent and NBS is operated by Orakum N.V. as a License Holder (Curacao license No. 8098/JAZ)</p>
                  <p className="mb-0">All rights reserved and protected by law.</p>
                  <p className="mb-0">Copyright © 2019 - 2022</p>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    }
  </>)
}

export default Footer;
