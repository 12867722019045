import axios from "axios";
import { apiPrefix, apiUrl } from "../../config/config";

class MatchService {

    static async getLiveMatchList() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/live_matches")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getPreMatchList() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/advancebets")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getUpcomingMatches() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/upcoming-matches")
            .then((response) => {
                return response.data;
            });
    }

    static async getSportsList() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/sports")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getSportMatchListById(id) {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/sport/"+ id +"/matchesWithEvents")
            .then((response) => {
                return response.data.data;
            });
    }
    static async getPreMatchListById(id) {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/sport/"+ id +"/pre-matches")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getMatchDetailsById(id) {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/match-details/"+ id)
            .then((response) => {
                return response.data.data;
            });
    }

    static async getBetDetailsById(id) {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/event-details/"+ id)
            .then((response) => {
                return response.data.data;
            });
    }
}

export default MatchService;