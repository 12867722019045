import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import selectAll from '../../../assets/images/select-all.png';

const LeftSidebar = ({ sports }) => {

    const location = useLocation();

    return (<>
        <div className="left-sidebar-content-area h-100">

            <div id="accordion" className="sport-card-accordion h-100">
                <div className="sport-card-accordion-header">Sports <span className="ms-1 collapse-d-none">(A-Z)</span></div>
                <div className="sport-card-accordion-list">
                    <div className="sport-card-level-one-wrapper"> 
                        <Link to="/sports/all" className={`sport-card-level-one d-block ${(location.pathname == "/sports/all" || location.pathname == "/") ? "active" : ""}`}>
                            <div className="sport-card-level-one-header">
                                <div className="sport-card-level-one-header-link">
                                    <span className="category-name">
                                        <span><img src={selectAll} alt="" /></span>
                                        <span className="name">All</span>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {sports && sports.length > 0 &&
                        <div className="sport-card-level-one-wrapper">
                            {sports.map((sport, index) => {
                                return (
                                    <Link to={"/sports/" + sport.id} className={`sport-card-level-one d-block ${location.pathname == "/sports/" + sport.id ? "active" : ""}`} key={index}>
                                        <div className="sport-card-level-one-header">
                                            <div className="sport-card-level-one-header-link">
                                                <span className="category-name">
                                                    <span><img src={sport.category_icon} alt="" /></span>
                                                    <span className="name">{sport.category_name}</span>
                                                </span>
                                                <span className="category-number">
                                                    <span className="number">{sport.matches.length}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>

        </div>


    </>)
}

const mapStateToProps = (state) => ({
    sports: state.match.sports
});

export default connect(mapStateToProps, null)(LeftSidebar);