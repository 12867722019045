import Echo from "laravel-echo";
import { socketUrl } from "../../config/config";
// import { io } from "socket.io-client";
// import socketio from "socket.io-client";
window.io = require('socket.io-client');


export const echoInit = async () => {
  // window.io = io;


  if (typeof window.io !== 'undefined'){
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: socketUrl,
      // transports: ["websocket", "polling", "flashsocket"],
    });
    // console.log(window.Echo);
  }
    
}

export const listenChannel = (channelName, eventName) => {

  window["Echo"].channel(channelName)
    .listen(eventName, data => {

      //Update Redux

    });
}




