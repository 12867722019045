import React from 'react';

const NotFoundMessage = ({ message = "" }) => {
  return (<>
    <div className="not-found-area mh-50vh d-flex align-items-center justify-content-center text-center">
      <div className="not-found-content">
        <h5 className="mb-0">{message}</h5>
      </div>
    </div>
  </>)
}
export default NotFoundMessage;