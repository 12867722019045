import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import selectAll from '../../../assets/images/select-all.png';

const SportsCategories = ({ sports, title, prefix = "" }) => {

  const location = useLocation();

  return (<>
    <div className="live-in-sports-category-area">
      <div className="live-in-play-carousel-parent-wrapper d-flex">
        {prefix != "/pre-match" &&
          <Link to="/sports/all" className={`live-in-play-carousel-item ${(location.pathname == prefix + "/sports/all" || location.pathname == "/" || location.pathname == prefix) ? "active" : ""}`}>
            <img className="d-flex" src={selectAll} alt="All" />
            <p className="mb-0 ms-2">All</p>
          </Link>
        }

        {sports && sports.length > 0 &&
          <>
            {sports.map((sport, index) => {
              return (
                <Link to={prefix + "/sports/" + sport.id} className={`live-in-play-carousel-item ${((location.pathname == prefix + "/sports/" + sport.id) || (location.pathname == "/pre-match" && index == 0)) ? "active" : ""}`} key={index}>
                  <img className="d-flex" src={sport.category_icon} alt={sport.category_name} />
                  <p className="mb-0 ms-2">{sport.category_name}</p>
                </Link>
              )
            })}
          </>
        }
      </div>
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  sports: state.match.sports
});

export default connect(mapStateToProps, null)(SportsCategories);