import React, { useEffect, useState } from 'react';
import Pagination from '../../../components/common/pagination/Pagination';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import UserService from '../../../services/api/user.service';

const BetHistory = () => {

  const [bets, setBets] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getList(1);
    }

    return () => {
      mounted = false;
    }
  }, []);

  const getList = (page) => {
    setIsLoaded(false);
    UserService.getBetHistory(page).then(
      (data) => {
        if (data) {
          // console.log('getBetHistory', data);
          setBets(data.data);
          setCurrentPage(data.current_page);
          setLastPage(data.last_page);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  }

  const handlePage = (page) => {
    // console.log("page", page);
    getList(page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  return (<>
    <div className="tab-pane" id="nav-bet-history" role="tabpanel">
      <div className="admin-content-header-new">Bet History</div>
      <div className="admin-content-scroll-wrapper">

        {isLoaded ?
          <div className="row admin-profile-table justify-content-center">
            <div className="col-lg-12">
              {bets && bets.length > 0 &&
                <div className="row box-card-row gx-2 d-none d-xs-flex">
                  {bets.map((bet, index) => {
                    return (
                      <div className="col-lg-4 mb-3" key={index}>
                        <div className="card box-card h-100">
                          <h5 className="card-header bg-dark text-white">{bet.bet_placed_at}</h5>
                          <div className="card-body">
                            <div className="row gx-2">
                              <div className="col-12">
                                <div className="d-block mt-1 mb-1">Tournament: <strong className="d-block">{bet.tournament}</strong></div>
                                <div className="d-block mt-1 mb-1">Match: <strong className="d-block">{bet.match_title}</strong></div>
                                <div className="row gx-2">
                                  <div className="col-6">
                                    <div className="d-block mt-1 mb-1">Bet Title:
                                      <strong className="d-block">{bet.bet_title}</strong>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="d-block mt-1 mb-1">Event:
                                      <strong className="d-block">{bet.bet_event.bet_event_title}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row gx-2 border-top bet-info">
                              <div className="col-4">
                                <div className="d-block mt-1">Bet Rate: <strong className="d-block">{bet.bet_rate}</strong></div>
                              </div>
                              <div className="col-4">
                                <div className="d-block mt-1">Bet Amount: <strong className="d-block">{bet.bet_amount}</strong>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="d-block mt-1">Status: <strong className="d-block">{bet.status}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }

              <div className="row">
                <div className="col-lg-12">
                  {bets && bets.length > 0 &&
                    <div className="card-table d-xs-none">
                      <table>
                        <thead>
                          <tr>
                            <th>Tournament</th>
                            <th>Match</th>
                            <th>Bet Title</th>
                            <th>Event</th>
                            <th>Bet Rate</th>
                            <th>Bet Amount</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bets.map((bet, index) => {
                            return (
                              <tr key={index}>
                                <td><strong>{bet.tournament}</strong></td>
                                <td>{bet.match_title}</td>
                                <td>{bet.bet_title}</td>
                                <td>{bet.bet_event.bet_event_title}</td>
                                <td><strong>{bet.bet_rate}</strong></td>
                                <td><strong>{bet.bet_amount}</strong></td>
                                <td>{bet.status}</td>
                                <td>{bet.bet_placed_at}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>
              {!(bets && bets.length > 0) &&
                <div className="row box-card-row mh-70v align-items-center">
                  <div className="col-lg-12 text-center">
                    <h5 className="mb-0 text-white-ts-80">No bet is Available</h5>
                  </div>
                </div>
              }
              {bets && bets.length > 0 &&
                <Pagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  handlePage={handlePage}
                />
              }

            </div>
          </div>
          :
          <LoadingSpinner />
        }
      </div>
    </div>
  </>)
}
export default BetHistory;