import axios from 'axios';
import { apiUrl, clientId, clientSecret } from '../../config/config';
import { getCookie, setCookie } from '../../helper/cookie';

const api = axios.create({
  baseURL: apiUrl,
});

const authApi = axios.create({
  baseURL: apiUrl,
});

authApi.interceptors.request.use(
  async (config) => {
    let access_token = getCookie('access_token');
    

    if (!access_token) {
      const refresh_token = getCookie('refresh_token');
      if (refresh_token) {
        const formData = new FormData();
        formData.append('client_id', clientId);
        formData.append('client_secret', clientSecret);
        formData.append('grant_type', "refresh_token");
        formData.append('refresh_token', refresh_token);
        const { data } = await axios.post(apiUrl + "/oauth/token", formData);
        // console.log("access_token", data);
        if (data) {
            // console.log('signIn interceptors', data);
            setCookie('access_token', data.access_token, data.expires_in);
            setCookie('refresh_token', data.refresh_token);
        }
        access_token = data.access_token;
      } else {
        window.location = "/sign-in";
      }
    }

    config.headers.common.Authorization = `Bearer ${access_token}`;
    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  },
);

export { api, authApi };
