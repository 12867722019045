import React from 'react';
import RegisterForm from '../components/common/form/RegisterForm';
import registerImage from '../assets/images/login-image.jpg';
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { appName } from '../config/config';
import LoginLayout from '../components/layouts/LoginLayout';

const Register = () => {

  return (<>
    <LoginLayout>
      <div className="login-register-page-area">
        <div className="login-register-registration-area">

          <div className="row gx-0">
            <div className="col-lg-6">
              <div className="login-register-form registration-form">
                <div className="logo mb-3 justify-content-center">
                  <Link to='/'>
                    <img src={logo} alt={appName} />
                    {/* XYZ */}
                  </Link>
                </div>
                <RegisterForm />

                <div className="rs-other-info py-1">
                  <span className="text-center d-block">
                    Already have an account? <Link to="/sign-in" className="rs-link rs-sign-in-link bg-transparent border-0">Sign In</Link>
                  </span>
                  <span className="text-center d-block mt-3">
                    <Link to="/" className="rs-link">Back to Home</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-xs-none">
              <div className="login-register-image h-100 position-relative">
                <img src={registerImage} alt="" className="w-100 h-100" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </LoginLayout>
  </>)
}
export default Register;