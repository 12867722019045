// URL CONFIG
export const apiUrl = 'https://apiclient.dlb24.com';
export const socketUrl = 'https://ss.dlb24.com:6002';
export const baseUrl = "https://dbx24.uk";

// CLIENT CONFIG
export const clientId = "11";
export const clientSecret = "MazTpwDG05uof0rHKsvWMdJGtf6XEN6yOS6jGZLT";

// API CONFIG
export const apiPrefix = "ng";

// APP CONFIG
export const appName = "dxb";
export const appWebsite = "";
export const devName = "";
export const devWebsite = "";
export const classPrefix = "dxb";