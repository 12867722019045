import { createSlice } from '@reduxjs/toolkit';

export const matchSlice = createSlice({
  name: 'match',
  initialState: {
    sports: null
  },
  reducers: {
    setStports: (state, action) => {
      state.sports = action.payload
    }
  },
});

export const { setStports } = matchSlice.actions;

export default matchSlice.reducer;
