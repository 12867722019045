import React, { useEffect, useState } from 'react';
import BetEvent from './BetEvent';

const BetList = ({ betList }) => {
    // console.log("betList", betList);
    const [betListData, setBetListData] = useState(betList);

    useEffect(() => {
      let mounted = true;
      if(mounted) {
        setBetListData(betList);
      }
    
      return () => {
        mounted = false;
      }
    }, [betList]);
    
    return (<>
        {betListData && betListData.length > 0 &&
            <div>
                {betListData.map((bet, index) => {
                    return (
                        <div className="single-match-bet-item" key={index}>
                            <div className="single-match-result-accordion-header" title={bet.bet_title}>
                                <button className="status bg-transparent border-0 p-0 text-white" title="Match Winner">{bet.bet_title}</button>
                            </div>
                            <div id={`single-match-result-accordion-${bet.id}`} className="collapse show">
                                <BetEvent betEvents={bet.bet_events} betStatus={bet.bet_status} />
                            </div>
                        </div>
                    )
                })}
            </div>
        }
    </>)
}

export default BetList;
