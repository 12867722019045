import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import Pagination from '../../../components/common/pagination/Pagination';
import { formatedDate } from '../../../helper/date';
import WalletService from '../../../services/api/wallet.service';

const DepositHistory = () => {

  const [deposits, setDeposits] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getList(1);
    }

    return () => {
      mounted = false;
    }
  }, []);

  const getList = (page) => {
    setIsLoaded(false);
    WalletService.getDepositHistory(page).then(
      (data) => {
        if (data) {
          // console.log('getdepositHistory', data);
          setDeposits(data.data);
          setCurrentPage(data.current_page);
          setLastPage(data.last_page);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  }

  const handlePage = (page) => {
    // console.log("page", page);
    getList(page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (<>
    <div className="tab-pane" id="nav-deposit-history" role="tabpanel">
      <div className="admin-content-header-new">Deposit History</div>
      <div className="admin-content-scroll-wrapper">
        {isLoaded ?
          <div className="row admin-profile-table justify-content-center">
            <div className="col-lg-12">
              {deposits && deposits.length > 0 &&
                <div className="row box-card-row gx-2 d-none d-xs-flex">
                  {deposits.map((deposit, index) => {
                    return (
                      <div className="col-lg-4 mb-3" key={index}>
                        <div className="card box-card h-100">
                          <h5 className="card-header bg-dark text-white">{formatedDate(deposit.updated_at, "DD/MM/YYYY hh:mm A")}</h5>
                          <div className="card-body">
                            <div className="row gx-2">
                              <div className="col-7">
                                <div className="d-block mt-1 mb-1">Amount/Coins: <strong
                                  className="text-nowrap">{deposit.coin_number}</strong></div>
                                <div className="d-block mt-1 mb-1">Method:
                                  <strong>{deposit.method}</strong>
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="d-block mt-1 mb-1">To: <strong>{deposit.phone_to}</strong></div>
                                <div className="d-block mt-1 mb-1">From: <strong>{deposit.phone_from}</strong>
                                </div>
                                <div className="d-block mt-1 mb-1">Status: <strong>{deposit.status}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }

              <div className="row">
                <div className="col-lg-12">
                  {deposits && deposits.length > 0 &&
                    <div className=" card-table d-xs-none">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount/Coins</th>
                            <th>Method</th>
                            <th>To No.</th>
                            <th>From No.</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deposits.map((deposit, index) => {
                            return (
                              <tr key={index}>
                                <td><strong>{deposit.date}</strong></td>
                                <td>{deposit.coin_number}</td>
                                <td>{deposit.method}</td>
                                <td>{deposit.phone_to}</td>
                                <td>{deposit.phone_from}</td>
                                <td><strong>{deposit.status}</strong></td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
              </div>
              {!(deposits && deposits.length > 0) &&
                <div className="row box-card-row mh-70v align-items-center">
                  <div className="col-lg-12 text-center">
                    <h5 className="mb-0 text-white-ts-80">No Deposit is Available</h5>
                  </div>
                </div>
              }
              {deposits && deposits.length > 0 &&
                <Pagination
                  currentPage={currentPage}
                  lastPage={lastPage}
                  handlePage={handlePage}
                />
              }

            </div>
          </div>
          :
          <LoadingSpinner />
        }
      </div>
    </div>
  </>)
}
export default DepositHistory;