import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './redux/store';
import MainLayout from './components/layouts/MainLayout';
import ScrollToTop from './components/common/scroll/ScrollToTop';
import { echoInit } from './services/app/echo.service';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {

  useEffect(() => {
    echoInit();
  }, [])
  
  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <ScrollToTop>
            <React.Suspense fallback={loading}>
              <MainLayout />
            </React.Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  )
}

export default App;
