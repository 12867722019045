import React from 'react';
import { Outlet } from 'react-router-dom';
import SportsCategories from '../common/match/SportsCategories';

const PreMatchLayout = () => {
  return (<>
    <div className={`home-layout h-100`}>
      {/* <h3 className="live-in-play-header">Pre Match/Advance Bets</h3> */}
      <div className="home-layout-body">
        <SportsCategories
          prefix="/pre-match" />
        <Outlet />
      </div>
    </div>
  </>)
}

export default PreMatchLayout;