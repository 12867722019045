import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../services/api/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { setMessage } from '../../../redux/slices/message';
import { clientId, clientSecret } from '../../../config/config';
import { useCookies } from 'react-cookie';
import { login } from '../../../redux/slices/auth';
import { Spinner } from 'react-bootstrap';

const SignInForm = ({ setLoginTabText, setMessage, login }) => {

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });


  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append('username', data.username ? data.username : '');
    formData.append('password', data.password ? data.password : '');
    formData.append('client_id', clientId);
    formData.append('client_secret', clientSecret);
    formData.append('grant_type', "password");
    setIsSubmitting(true);
    AuthService.signIn(formData).then(
      (data) => {
        if (data) {
          // console.log('signIn', data);
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + (data.expires_in * 1000);
          now.setTime(expireTime);
          setCookie('access_token', data.access_token, { path: '/', expires: now });
          setCookie('refresh_token', data.refresh_token, { path: '/' });
          login({
            loaded: true,
            loggedIn: true,
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
          });
          setMessage({
            type: "success",
            title: "Sign In Successfully.",
          });
          setTimeout(() => {
            navigate('/');
          }, 300);
        }
        setIsSubmitting(false);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.message);
        setMessage({
          type: "error",
          title: error?.response?.data?.message,
        });
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        return Promise.reject();
      }
    );
  };

  // console.log("cookies", cookies);

  return (<>
    <form className="rs-form max-width-400" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row form-inputs-wrapper">
        <div className="col-lg-12">
          <div className="form-group-input-wrapper">
            <input
              className="form-control"
              type="text"
              placeholder="Username"
              {...register("username", {
                required: "Username required"
              })}
            />
            {errors.username && <span className="input-validation">{errors.username.message}</span>}
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group-input-wrapper">
            <div className="form-group-input-password-wrapper">
              <input
                className="form-control"
                type={passwordMode.password}
                placeholder="Password"
                {...register("password", {
                  required: "Password required"
                })}
              />
              <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
              </button>
            </div>
            {errors.password && <span className="input-validation">{errors.password.message}</span>}
          </div>
        </div>

        <div className="col-lg-12">
          <div className="rs-other-info mt-0 flex-row justify-content-between">
            <span className="left-side">
              <input type="checkbox" className="form-check-input" id="fsid_remember-check" name="fsid_remember-check" />
              <label className="form-check-label ms-1" id="fsid_remember-check-label" htmlFor="fsid_remember-check">Remember</label>
            </span>
            <span className="right-side">
              <Link to="/forgot-password" className="rs-link rs-forget-password-link bg-transparent border-0">Forgot Password?</Link>
              {/* <button type="button" className="rs-link rs-forget-password-link bg-transparent border-0" onClick={() => setLoginTabText("Forget Password")}>Forgot Password?</button> */}
            </span>
          </div>
          <div className="rs-submit">
            <button type="submit" name="fsid_reg-submit" disabled={isSubmitting} >
              {isSubmitting ?
                <span className="loading d-inline-flex align-items-center justify-content-center">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
                :
                <span>Sign in</span>
              }
            </button>
          </div>
        </div>
      </div>

    </form>
  </>)
}

export default connect(null, { setMessage, login })(SignInForm);