import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';

const ProfileDetails = ({ user }) => {
  // console.log("user", user);

  const [userData, setUserData] = useState(user);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user) {
        setUserData(user);
      } else {
        setUserData(null);
      }

      setTimeout(() => {
        setIsLoaded(true);
      }, 500);
    }
    return () => {
      mounted = false;
    }
  }, [user]);

  return (<>
    <div className="tab-pane" id="nav-view-profile" role="tabpanel">
      <div className="admin-content-header-new">Personal Profile</div>
      <div className="admin-content-scroll-wrapper">
        {isLoaded ?
          <>
            {userData ?
              <div className="row admin-profile-table admin-profile-table-v-2">
                <div className="col-lg-6">
                  <div className="card-table">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Account Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Username</td>
                          <td>{userData.username}</td>
                        </tr>
                        <tr>
                          <td>Join Date</td>
                          <td>{userData.created_at}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          {!userData.status &&
                            <td>Inactive</td>
                          }
                          {userData.status &&
                            <td>Active</td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-table">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Contact Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Email</td>
                          {userData.email &&
                            <td>{userData.email}</td>
                          }
                          {!userData.email &&
                            <td>Not Set</td>
                          }
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{userData.phone}</td>
                        </tr>
                        <tr>
                          <td>Country</td>
                          {userData.country &&
                            <td>{userData.country.name}</td>
                          }
                          {!userData.country &&
                            <td>Not Set</td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card-table">
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Others</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <td>Club</td>
                          {userData.clubs?.clubname &&
                            <td>{userData.clubs?.clubname}</td>
                          }
                          {!userData.clubs?.clubname &&
                            <td>N/A</td>
                          }
                        </tr>
                        <tr>
                          <td>Sponsor Name</td>
                          {userData.sponsorname &&
                            <td>{userData.sponsorname}</td>
                          }
                          {!userData.sponsorname &&
                            <td>Not Available</td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              :
              <div className="row box-card-row mh-70v align-items-center">
                <div className="col-lg-12 text-center">
                  <h5 className="mb-0 text-white-ts-80">No Profile Data is Available</h5>
                </div>
              </div>
            }
          </>
          :
          <LoadingSpinner />
        }
      </div>
    </div>
  </>)
}
const mapStateToProps = (state) => ({
  user: state.auth.auth.user
});
export default connect(mapStateToProps, null)(ProfileDetails);