import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../components/common/loader/LoadingSpinner";
import Pagination from "../../../components/common/pagination/Pagination";
import WalletService from "../../../services/api/wallet.service";
import * as moment from "moment";

const WithdrawHistory = () => {
  const [withdraws, setWithdraws] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getList(1);
    }

    return () => {
      mounted = false;
    };
  }, []);

  const getList = (page) => {
    setIsLoaded(false);
    WalletService.getWithdrawHistory(page).then(
      (data) => {
        if (data) {
          // console.log('getWithdrawHistory', data);
          setWithdraws(data.data);
          setCurrentPage(data.current_page);
          setLastPage(data.last_page);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  };

  const handlePage = (page) => {
    // console.log("page", page);
    getList(page);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = moment(dateString).format("DD/MM/YYYY, h:mm a"); ;

    return formattedDate;
  };

  return (
    <>
      <div className="tab-pane" id="nav-withdraw-history" role="tabpanel">
        <div className="admin-content-header-new">Withdraw History</div>
        <div className="admin-content-scroll-wrapper">
          {isLoaded ? (
            <div className="row admin-profile-table justify-content-center">
              <div className="col-lg-12">
                {withdraws && withdraws.length > 0 && (
                  <div className="row box-card-row gx-2 d-none d-xs-flex">
                    {withdraws.map((withdraw, index) => {
                      return (
                        <div className="col-lg-4 mb-3" key={index}>
                          <div className="card box-card h-100">
                            <h5 className="card-header bg-dark text-white">
                              {withdraw.date}
                            </h5>
                            <div className="card-body">
                              <div className="row gx-2">
                                <div className="col-8">
                                  <div className="d-block mt-1 mb-1">
                                    Amount/Coins:{" "}
                                    <strong className="text-nowrap">
                                      {withdraw.withdraw_amount}
                                    </strong>
                                  </div>
                                  <div className="d-block mt-1 mb-1">
                                    Method:
                                    <strong>{withdraw.method}</strong>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="d-block mt-1 mb-1">
                                    To: <strong>{withdraw.phone_no}</strong>
                                  </div>
                                  <div className="d-block mt-1 mb-1">
                                    Ref.: <strong>{withdraw.reference}</strong>
                                  </div>
                                  <div className="d-block mt-1 mb-1">
                                    Status: <strong>{withdraw.status}</strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12">
                    {withdraws && withdraws.length > 0 && (
                      <div className=" card-table d-xs-none">
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Amount/Coins</th>
                              <th>Method</th>
                              <th>To No.</th>
                              <th>Referrence</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdraws.map((withdraw, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <strong>{formatDate(withdraw.date)}</strong>
                                  </td>
                                  <td>{withdraw.withdraw_amount}</td>
                                  <td>{withdraw.payment_method}</td>
                                  <td>{withdraw.phone_no}</td>
                                  <td>{withdraw.reference}</td>
                                  <td>
                                    <strong>{withdraw.status}</strong>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
                {!(withdraws && withdraws.length > 0) && (
                  <div className="row box-card-row mh-70v align-items-center">
                    <div className="col-lg-12 text-center">
                      <h5 className="mb-0 text-white-ts-80">
                        No Withdraw is Available
                      </h5>
                    </div>
                  </div>
                )}

                {withdraws && withdraws.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    lastPage={lastPage}
                    handlePage={handlePage}
                  />
                )}
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </>
  );
};
export default WithdrawHistory;
