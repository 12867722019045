import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <div className="error-page-area d-flex align-items-center justify-content-center position-fixed start-0 top-0 w-100 h-100">
      <div className="error-page-content text-center text-white">
        <h1 className="mb-0">404</h1>
        <p>Page Not Found</p>
        <Link to="/" className="home-link">Back to Home</Link>
      </div>
    </div>
  )
}
export default Error404;