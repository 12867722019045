import { apiPrefix } from "../../config/config";
import { authApi } from "../interceptor/auth.interceptor";

class WalletService {

    static async getDepositNumber() {
        return await authApi
            .get("/" + apiPrefix + "/user/wallet/deposit-number")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getDepositNumberList() {
        return await authApi
            .get("/" + apiPrefix + "/user/wallet/deposit-numbers")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getDepositHistory(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/wallet/deposit-history?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async getWithdrawHistory(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/wallet/withdraw-history?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async getStatementList(page = 1) {
        return await authApi
            .get("/" + apiPrefix + "/user/wallet/statement?page=" + page)
            .then((response) => {
                return response.data.data;
            });
    }

    static async makeDeposit(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/wallet/deposit-request", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async coinTransfer(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/wallet/cointransfer", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async withdraw(formData) {
        return await authApi
            .post("/" + apiPrefix + "/user/wallet/withdraw", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }
}

export default WalletService;