import Alert from 'react-bootstrap/Alert';
import React, { useEffect, useState } from 'react';

const AlertDismissible = ({ message = "", setErrorMessage }) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (message) {
        setShow(true);
      } else {
        setShow(false);
      }
    }

    return () => {
      mounted = false;
    }
  }, [message]);

  return (<>
    {show &&
      <Alert variant="danger" onClose={() => {
        setShow(false);
        setErrorMessage("");
      }} dismissible>
        <Alert.Heading>Error!</Alert.Heading>
        <p>
          {message}
        </p>
      </Alert>
    }
  </>)
}
export default AlertDismissible;