import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { classPrefix } from '../../config/config';
import websiteRoutes from '../../routes/website';
import { ToastContainer, toast } from 'react-toastify';
import BetSlip from '../common/bet/BetSlip';

const MainLayout = ({ message }) => {

  const toastOption = {
    autoClose: 1000,
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log(message);      
      if (message && message.title) {
        switch (message.type) {
          case "success":
            toast.success(message.title, toastOption);
            break;
          case "info":
            toast.info(message.title, toastOption);
            break;
          case "warn":
            toast.warn(message.title, toastOption);
            break;
          case "error":
            toast.error(message.title, toastOption);
            break;
          default:
            toast(message.title, toastOption);
            break;
        }
      }
    }
    return () => {
      mounted = false;
    }
  }, [message]);


  const getPath = (route) => {
    return route.path ? route.path : ""
  }

  return (<>
    <ToastContainer theme="colored" position="top-center" className="toast-position" />
    <div className={`${classPrefix} main-layout`}>
      <div className="d-none d-xs-block">
        <BetSlip />
      </div>
      {websiteRoutes && websiteRoutes.length > 0 &&
        <Routes>
          {websiteRoutes.map((route1, index1) => {
            let path = "";
            if (route1.layout) {
              let Layout1 = route1.layout;
              path = getPath(route1);
              // console.log("path1 if", path);
              return (
                <Route element={<Layout1 />} key={index1}>
                  {route1.children && route1.children.length > 0 && route1.children.map((route2, index2) => {
                    if (route2.layout) {
                      let Layout2 = route2.layout;
                      path = getPath(route1) + getPath(route2);
                      // console.log("path2 if", path);
                      return (
                        <Route element={<Layout2 />} key={index2}>
                          {route2.children && route2.children.length > 0 && route2.children.map((route3, index3) => {
                            if (route3.layout) {
                              let Layout3 = route3.layout;
                              path = getPath(route1) + getPath(route2) + getPath(route3);
                              // console.log("path3 if", path);
                              <Route element={<Layout3 />} key={index3}>
                                {route3.children && route3.children.length > 0 && route3.children.map((route4, index4) => {
                                  let Component4 = route4.element;
                                  path = getPath(route1) + getPath(route2) + getPath(route3) + getPath(route4);
                                  // console.log("path4 else", path);
                                  return (
                                    <Route path={path} exact={route4.exact ? route4.exact : false} element={<Component4 />} key={index4} />
                                  )
                                })}
                              </Route>
                            } else {
                              let Component3 = route3.element;
                              path = getPath(route1) + getPath(route2) + getPath(route3);
                              // console.log("path3 else", path);
                              return (
                                <Route path={path} exact={route3.exact ? route3.exact : false} element={<Component3 />} key={index3} />
                              )
                            }
                          })}
                        </Route>
                      )
                    } else {
                      let Component2 = route2.element;
                      path = getPath(route1) + getPath(route2);
                      // console.log("path2 else", path);
                      return (
                        <Route path={path} exact={route2.exact ? route2.exact : false} element={<Component2 />} key={index2} />
                      )
                    }
                  })}
                </Route>
              )
            } else {
              let Component1 = route1.element;
              path = getPath(route1);
              // console.log("path1 else", path);
              return (
                <Route path={path} exact={route1.exact ? route1.exact : false} element={<Component1 />} key={index1} />
              )
            }
          })}
        </Routes>
      }
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  message: state.message.message
});

export default connect(mapStateToProps, null)(MainLayout);