import { faClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { switchBetSlipMode } from '../../../redux/slices/common';
import { addBet, removeBet } from '../../../redux/slices/bet';
import { Spinner } from 'react-bootstrap';
import { setMessage } from '../../../redux/slices/message';
import UserService from '../../../services/api/user.service';

const BetSlip = ({ betSlipShow, switchBetSlipMode, activeBet, removeBet, loggedIn, setMessage }) => {

  // console.log("loggedIn", loggedIn);
  // console.log("activeBet", activeBet);
  const [errorMessage, setErrorMessage] = useState("");
  const [returnAmount, setReturnAmount] = useState(0);
  const [inputAmount, setInputAmount] = useState(20);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (activeBet) {
        calculateReturnAmount(inputAmount);
      } else {
        switchBetSlipMode(false);
      }
    }

    return () => {
      mounted = false;
    }
  }, [activeBet]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!loggedIn) {
        removeBet();
        switchBetSlipMode(false);
      }
    }

    return () => {
      mounted = false;
    }
  }, [loggedIn]);


  const calculateReturnAmount = (stake) => {
    // console.log("stake", stake);
    if (activeBet) {
      if (validateStakeInput(stake)) {
        let returnAmountDummy = activeBet.bet_ratio * stake;
        // console.log("returnAmount", returnAmountDummy);
        setReturnAmount(returnAmountDummy);
      } else {
        setReturnAmount(0);
      }
    } else {
      setReturnAmount(0);
    }
  }


  const validateStakeInput = (stake) => {
    if (stake < 20) {
      setErrorMessage("Stake Should not be less than 20.");
      return false;
    } else if (activeBet && stake > activeBet.bets.cash_limit) {
      setInputAmount(activeBet.bets.cash_limit);
      setErrorMessage("Stake should not be greater than " + activeBet.bets.cash_limit);
      return false;
    }
    setErrorMessage('');
    return true;
  }

  const handleIncreaseAmount = () => {
    if (activeBet) {
      let newAmmount = parseInt(inputAmount) + 5;
      setInputAmount(newAmmount);
      calculateReturnAmount(newAmmount);
    }
  }

  const handleDecreaseAmount = () => {
    if (activeBet) {
      let newAmmount = parseInt(inputAmount) - 5;
      if (newAmmount > 0) {
        setInputAmount(newAmmount);
        calculateReturnAmount(newAmmount);
      } else {
        setInputAmount(0);
        calculateReturnAmount(0);
      }
    }
  }

  const handleChangeAmount = (e) => {
    if (activeBet) {
      let newAmmount = e.target.value;
      if (newAmmount > 0) {
        setInputAmount(newAmmount);
        calculateReturnAmount(newAmmount);
      } else {
        setInputAmount(0);
        calculateReturnAmount(0);
      }
    }
  }

  const handleSubmitBet = (e) => {
    if (activeBet) {
      if (loggedIn) {
        const formData = new FormData();
        formData.append('bet_amount', inputAmount ? inputAmount : '');
        formData.append('bet_event_id', activeBet && activeBet.id ? activeBet.id : '');
        setIsSubmitting(true);
        UserService.submitBet(formData).then(
          (data) => {
            // console.log('submitBet', data);
            if (data && data.status == "success") {
              setMessage({
                type: "success",
                title: "Bet Placed Successfully",
              });
              removeBet();
            } else {
              setMessage({
                type: "error",
                title: "Something went wrong. Please try again!",
              });
            }
            setTimeout(() => {
              setIsSubmitting(false);
            }, 2100);
            return Promise.resolve();
          },
          (error) => {
            // console.log(error?.response?.data?.errors?.message);
            setMessage({
              type: "error",
              title: error?.response?.data?.errors?.message,
            });
            setTimeout(() => {
              setIsSubmitting(false);
            }, 2100);
            return Promise.reject();
          }
        );
      } else {
        setMessage({
          type: "error",
          title: "Please Log In!",
        });
      }
    }
  }

  return (<>
    <div className={`betslip-card-area responsive ${betSlipShow ? 'active' : ''}`}>
      <div className="betslip-card-area-responsive-overlay" onClick={() => switchBetSlipMode(!betSlipShow)}></div>
      <div className="betslip-card">

        <div className="betslip-card-header no-border">
          <span className="betslip-card-header-text">
            <span className="name">Betslip</span>
          </span>
          <div className="betslip-card-close" onClick={() => switchBetSlipMode(!betSlipShow)}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>

        <div className="betslip-card-body-wrapper">
          <div className="betslip-card-body">

            <div className="betslip-actions betslip-message text-center">
              {activeBet &&
                <h5 className="bet-range-message text-capitalize">Minumim 20 &amp; Maximum {activeBet.bets.cash_limit}</h5>
              }
              {!activeBet &&
                <h5 className="bet-range-message text-capitalize">Please take any bet</h5>
              }
              {errorMessage && activeBet &&
                <h5 className="bet-error-message">{errorMessage}</h5>
              }
            </div>
            {activeBet &&
              <div className="betslip-item-list betslip-item-list-v2">

                <div className="single-betlist-item colored-header">
                  <span className="betlist-text">
                    <span className="match-title-image colored-title">
                      <span className="match-title fw-400">{activeBet.bets.matches.match_title}</span>
                    </span>
                    <div className="match-team-list-wrapper position-relative d-flex flex-column justify-content-start align-items-start">
                      <span className="match-title-image">
                        {/* <span className="icon">
                        <img src={flag} alt="" />
                      </span> */}
                        <span className="match-title">{activeBet.bets.matches.first_team}</span>
                      </span>

                      <span className="match-title-image">
                        {/* <span className="icon">
                        <img src={flag} alt="" />
                      </span> */}
                        <span className="match-title">{activeBet.bets.matches.second_team}</span>
                      </span>
                    </div>

                    <span className="single-result-item">
                      <span className="right-side">{activeBet.bet_ratio.toFixed(2)}</span>
                      <span className="left-side">{activeBet.bets.bet_title} - {activeBet.bet_event_title}</span>
                    </span>
                  </span>
                  <button type="button" className="betlist-single-item-remove-icon" onClick={() => removeBet()}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            }

            <div className="betslip-bid-area">
              <ul>
                {activeBet &&
                  <li>
                    <span className="left-side">Overall odds</span>
                    <span className="right-side">{activeBet.bet_ratio.toFixed(2)}</span>
                  </li>
                }
                <li className="align-items-start">
                  <span className="left-side">Stake</span>
                  <span className="right-side">
                    <span className="input-number-wrapper">
                      <button className="input-number minus border-0" onClick={() => handleDecreaseAmount()}>-</button>
                      <input min="0" type="number" pattern="[0-9]+" value={inputAmount} onChange={(e) => handleChangeAmount(e)} />
                      <button className="input-number plus border-0" onClick={() => handleIncreaseAmount()}>+</button>
                    </span>
                    {/* <a className="release-bonus-link">
                      <span>Release Bonus Credit</span>
                      <span>Cancel Bonus Credit</span>
                    </a> */}
                  </span>
                </li>

                <li>
                  <span className="left-side">Possible Win</span>
                  <span className="right-side possible-win blue">{returnAmount.toFixed(2)}</span>
                </li>
              </ul>
            </div>

            <button type="button" className="accept-changes-btn d-flex align-items-center justify-content-center" onClick={() => handleSubmitBet()} disabled={isSubmitting}>
              {isSubmitting ?
                <span className="loading d-inline-flex align-items-center justify-content-center">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
                :
                <span>Submit Bet</span>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  betSlipShow: state.common.betSlipShow,
  activeBet: state.bet.activeBet,
  loggedIn: state.auth.auth.loggedIn
});

export default connect(mapStateToProps, { switchBetSlipMode, addBet, removeBet, setMessage })(BetSlip);