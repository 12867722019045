import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/common/loader/LoadingSpinner';
import MatchList from '../../components/common/match/MatchList';
import MatchService from '../../services/api/match.service';

const PreMatchSportsDetails = () => {

  const params = useParams();
  // console.log("params", params);

  const [isLoaded, setIsLoaded] = useState(false);
  const [matchList, setMatchList] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getPreMatchListById(params.id);
    }

    return () => {
      mounted = false;
    }
  }, [params]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('reload_matches')
        .listen('MatchEvents', (e) => {
          // console.log("WS");
          if (params && params.id) {
            getPreMatchListById(params.id);
          }
        });

      return () => {
        window.Echo.leaveChannel("reload_matches");
      };
    }
  }, [matchList, params]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('bet-ratio-change')
        .listen('MatchEvents', (e) => {
          // console.log(e);
          if (matchList && matchList.length > 0) {
            let matchListDummy = [...matchList];
            matchListDummy.map((match) => {
              match.bets.map((bet) => {
                if (bet.id == e.betId) {
                  bet.bet_events.map((betEvent, index) => {
                    const eventData = e.data;
                    const item = eventData.find(item => item.id === betEvent.id);
                    // console.log("item", item);
                    if (item) {
                      bet.bet_events[index].bet_ratio = item.bet_ratio;
                    }
                  });
                }
              });
            });
            // console.log("matchListDummy", matchListDummy);
            setMatchList(matchListDummy);
          }
        });

      return () => {
        window.Echo.leaveChannel("bet-ratio-change");
      };
    }
  }, [matchList]);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('match-bet-update')
        .listen('MatchEvents', (e) => {
          // console.log(e);
          if (matchList && matchList.length > 0) {
            let matchListDummy = [...matchList];
            matchListDummy.map((match, index) => {
              if (match.id === e.match_id) {
                matchListDummy[index].bets = e.data;
              }
            });
            setMatchList(matchListDummy);
          }
        });

      return () => {
        window.Echo.leaveChannel("match-bet-update");
      };
    }
  }, [matchList]);

  const getPreMatchListById = (id) => {
    setIsLoaded(false);
    MatchService.getPreMatchListById(id).then(
      (data) => {
        if (data) {
          // console.log('getPreMatchListById', data);
          setMatchList(data);
        }
        setIsLoaded(true);
        return Promise.resolve();
      },
      (error) => {
        // console.log(error);
        setIsLoaded(true);
        return Promise.reject();
      }
    );
  }

  return (<>
    {isLoaded ?
      <MatchList
        matchList={matchList}
      />
      :
      <LoadingSpinner />
    }
  </>)
}
export default PreMatchSportsDetails;