import React from 'react';
import UserLeftSidebar from './UserLeftSidebar';
import { connect } from 'react-redux';
import { switchMobileMenuMode } from "../../../redux/slices/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const MobileSidebar = ({ mobileMenuShow, switchMobileMenuMode }) => {

    return (<>
        <div className={`mobile-sidebar-area ${mobileMenuShow ? 'active' : ''}`}>
            <button className="mobile-sidebar-backdrop border-0 w-100 h-100 position-fixed start-0 top-0" onClick={() => switchMobileMenuMode(!mobileMenuShow)}></button>
            <div className="mobile-sidebar-wrapper h-100 position-fixed start-0 top-0">
                <button className="mobile-sidebar-close border-0 position-absolute end-0 top-0 text-white bg-transparent d-flex align-items-center justify-content-center" onClick={() => switchMobileMenuMode(!mobileMenuShow)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <UserLeftSidebar />
            </div>
        </div>
    </>)
}

const mapStateToProps = (state) => ({
    mobileMenuShow: state.common.mobileMenuShow
});

export default connect(mapStateToProps, { switchMobileMenuMode })(MobileSidebar);