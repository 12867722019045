import React from 'react';
import { Outlet } from 'react-router-dom';
import SportsCategories from '../common/match/SportsCategories';

const HomeLayout = () => {
  return (<>
    <div className={`home-layout h-100`}>
      {/* <h3 className="live-in-play-header">Live In-Play</h3> */}
      <div className="home-layout-body">
        <SportsCategories />
        <Outlet />
      </div>
    </div>
  </>)
}

export default HomeLayout;