import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Marquee from "react-fast-marquee";
import SiteService from '../../../services/api/site.service';

const HeaderNotice = () => {

  const [siteNotices, setSiteNotices] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      SiteService.getSiteNotice().then(
        (data) => {
          if (data && data.length > 0) {
            // console.log('getSiteNotice', data);
            setSiteNotices(data);
            const body = document.getElementsByTagName('body')[0];
            body.classList.add('has-notice-bar');
          } else {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('has-notice-bar');
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('has-notice-bar');
          return Promise.reject();
        }
      );

    }

    return () => {
      mounted = false;
    }
  }, []);

  return (<>
    {siteNotices && siteNotices.length > 0 &&
      <div className="notice-bar-area">
        <div className="notice-text">Notice</div>
        <div className="notice-lists">
          <Marquee
            gradient={false}
          >
            <ul>
              {siteNotices.map((notice, index) => {
                return (
                  <li key={index}>{notice?.message}</li>
                )
              })}
            </ul>            
          </Marquee>
        </div>
      </div>
    }
  </>)
}
export default HeaderNotice;