import { IconCash, IconCashBanknote, IconCoin, IconDice5, IconFileDescription, IconFileDollar, IconFileInvoice, IconFileSpreadsheet, IconHeartHandshake, IconLock, IconMessages, IconUser, IconUserCheck, IconUserCircle, IconUsers } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { switchMobileMenuMode } from "../../../redux/slices/common";
import { setMessage } from '../../../redux/slices/message';
import { logout, setUser } from '../../../redux/slices/auth';
import UserService from '../../../services/api/user.service';

const UserLeftSidebar = ({ user, switchMobileMenuMode, setMessage, logout, setUser, updatedCoinsChange }) => {
  const [updatedCoins, setUpdatedCoins] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [userData, setUserData] = useState(user);
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user) {
        setUserData(user);
      } else {
        setUserData(null);
      }
    }
    return () => {
      mounted = false;
    }
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      switchMobileMenuMode(false);
    }
    return () => {
      mounted = false;
    }
  }, [location]);

  useEffect(() => {
    let mounted = true;
    if (mounted && updatedCoinsChange) {
      UserService.getCoins().then(
        (data) => {
          if (data) {
            // console.log('getCoins', data);
            setUpdatedCoins(data.current_balance);
          }
          return Promise.resolve();
        },
        (error) => {
          console.log(error?.response);
          return Promise.reject();
        }
      );
    }
    return () => {
      mounted = false;
    }
  }, [updatedCoinsChange]);

  const handleLogout = () => {
    logoutCookie();
    switchMobileMenuMode(false);
    setMessage({
      type: "success",
      title: "Logout Successfully.",
    });
  }

  const logoutCookie = () => {
    removeCookie('access_token', { path: '/' });
    removeCookie('refresh_token', { path: '/' });
    setUser(null);
    setUserData(null);
    logout();
  }

  return (<>

    <div className="left-sidebar-wrapper user-left-sidebar-wrapper">

      {/* <div className="admin-left-sidebar-toggle-btn-area">
          <div className="admin-left-sidebar-toggle-btn-wrapper text-uppercase d-flex align-items-center">
              <span className="text">My Account</span>
          </div>
      </div> */}
      {userData &&
        <div className="admin-profile-info-area d-flex">
          <div className="admin-profile-info-icon me-3">
            <IconUser />
            {/* <FontAwesomeIcon icon={faUserCircle} /> */}
          </div>
          <div className="admin-profile-text">
            <div className="admin-profile-username">{userData?.username}</div>
            <div className="main-balance">
              <p className="d-flex align-items-center"><span className="badge bg-warning text-dark me-2">{updatedCoins != null ? updatedCoins : userData.current_balance}</span> <span className="d-inline-flex">COINS</span></p>
            </div>
          </div>

          <button type="button" className="sidebar-logout-button d-none d-xs-block px-2 border-0 mt-auto ms-auto" onClick={() => handleLogout()}>Logout</button>
        </div>
      }


      <div className="admin-profile-sidebar-menu-area">
        <div className="admin-profile-sidebar-menu-group">
          <h4>Personal Profile</h4>
          <div className="admin-profile-sidebar-menu-list">
            <ul>
              <li>
                <Link className="nav-item" to="/profile/view-profile">
                  <span className="icon">
                    <IconUser />
                    {/* <FontAwesomeIcon icon={faUser} /> */}
                  </span>
                  <span className="text">View Profile</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/profile/update-profile">
                  <span className="icon">
                    <IconUserCheck />
                    {/* <FontAwesomeIcon icon={faUserEdit} /> */}
                  </span>
                  <span className="text">Update Profile</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/profile/update-club">
                  <span className="icon">
                    <IconHeartHandshake />
                    {/* <FontAwesomeIcon icon={faHandshake} /> */}
                  </span>
                  <span className="text">Update Club</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/profile/my-followers">
                  <span className="icon">
                    <IconUsers />
                    {/* <FontAwesomeIcon icon={faUserFriends} /> */}
                  </span>
                  <span className="text">My Followers</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/profile/bet-history">
                  <span className="icon">
                    <IconDice5 />
                    {/* <FontAwesomeIcon icon={faDice} /> */}
                  </span>
                  <span className="text">Bet History</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/profile/update-password">
                  <span className="icon">
                    <IconLock />
                    {/* <FontAwesomeIcon icon={faKey} /> */}
                  </span>
                  <span className="text">Update Password</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="admin-profile-sidebar-menu-area">
        <div className="admin-profile-sidebar-menu-group">
          <h4>Personal Wallet</h4>
          <div className="admin-profile-sidebar-menu-list">
            <ul>
              <li>
                <Link className="nav-item" to="/wallet/make-deposit">
                  <span className="icon">
                    <IconCashBanknote />
                    {/* <FontAwesomeIcon icon={faMoneyBillAlt} /> */}
                  </span>
                  <span className="text">Make Deposit</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/wallet/deposit-history">
                  <span className="icon">
                    <IconFileInvoice />
                    {/* <FontAwesomeIcon icon={faFileInvoice} /> */}
                  </span>
                  <span className="text">Deposit History</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/wallet/coin-transfer">
                  <span className="icon">
                    <IconCash />
                    {/* <FontAwesomeIcon icon={faCoins} /> */}
                  </span>
                  <span className="text">Coin Transfer</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/wallet/withdraw">
                  <span className="icon">
                    <IconCoin />
                    {/* <FontAwesomeIcon icon={faDollarSign} /> */}
                  </span>
                  <span className="text">Withdraw</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/wallet/withdraw-history">
                  <span className="icon">
                    <IconFileDollar />
                    {/* <FontAwesomeIcon icon={faFileInvoiceDollar} /> */}
                  </span>
                  <span className="text">Withdraw History</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/wallet/statement">
                  <span className="icon">
                    <IconFileDescription />
                    {/* <FontAwesomeIcon icon={faFileAlt} /> */}
                    <i className="fas fa-file-alt"></i>
                  </span>
                  <span className="text">Statement</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="admin-profile-sidebar-menu-area">
        <div className="admin-profile-sidebar-menu-group">
          <h4>Support</h4>
          <div className="admin-profile-sidebar-menu-list">
            <ul>
              <li>
                <Link className="nav-item" to="/support/submit-complain">
                  <span className="icon">
                    <IconMessages />
                    {/* <FontAwesomeIcon icon={faComment} /> */}
                  </span>
                  <span className="text">Submit Complain</span>
                </Link>
              </li>
              <li>
                <Link className="nav-item" to="/support/complain-history">
                  <span className="icon">
                    <IconFileSpreadsheet />
                    {/* <FontAwesomeIcon icon={faCopy} /> */}
                  </span>
                  <span className="text">Complain History</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>


  </>)
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
  updatedCoinsChange: state.common.updatedCoinsChange
});
export default connect(mapStateToProps, { switchMobileMenuMode, setMessage, logout, setUser })(UserLeftSidebar);