import { createSlice } from '@reduxjs/toolkit';

export const betSlice = createSlice({
  name: 'bet',
  initialState: {
    activeBet: null,
  },
  reducers: {
    addBet: (state, action) => {
      state.activeBet = action.payload;
    },
    removeBet: (state, action) => {
      state.activeBet = null;
    },
  },
});

export const { addBet, removeBet } = betSlice.actions;

export default betSlice.reducer;
