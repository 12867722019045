import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatedDate } from '../../../helper/date';
import MatchService from '../../../services/api/match.service';
import BetEvent from '../bet/BetEvent';

const UpcomingMatches = () => {

  const [upcomingMatches, setUpcomingMatches] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      MatchService.getUpcomingMatches().then(
        (data) => {
          if (data) {
            // console.log('getPreMatchList', data);
            setUpcomingMatches(data);
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);

  useEffect(() => {
    if (window.Echo) {
      window.Echo.channel('bet-ratio-change')
        .listen('MatchEvents', (e) => {
          // console.log(e);
          if (upcomingMatches && upcomingMatches.length > 0) {
            let upcomingMatchesDummy = [...upcomingMatches];
            upcomingMatchesDummy.map((match) => {
              match.bets.map((bet) => {
                if (bet.id == e.betId) {
                  bet.bet_events.map((betEvent, index) => {
                    const eventData = e.data;
                    const item = eventData.find(item => item.id === betEvent.id);
                    // console.log("item", item);
                    if (item) {
                      bet.bet_events[index].bet_ratio = item.bet_ratio;
                    }
                  });
                }
              });
            });
            // console.log("upcomingMatchesDummy", upcomingMatchesDummy);
            setUpcomingMatches(upcomingMatchesDummy);
          }
        });

      return () => {
        window.Echo.leaveChannel("bet-ratio-change");
      };
    }
  }, [upcomingMatches]);

  return (<>
    {upcomingMatches && upcomingMatches.length > 0 &&
      <div className="upcomming-matches-area">
        <div className="upcomming-matches-section-title">Upcoming Matches</div>
        <div className="upcomming-matches-list-wrapper">
          <div className="upcomming-matches-list">
            {upcomingMatches.map((upcomingMatch, index) => {
              if (upcomingMatch) {
                return (
                  <div className="single-upcomming-match-card flex-column" key={index}>
                    <div className="upcomming-match-date-details d-flex">
                      <div className="upcomming-match-date">
                        <span className="date">{formatedDate(upcomingMatch.match_date, "DD", true)}</span>
                        <span className="month">{formatedDate(upcomingMatch.match_date, "MMM", true)}</span>
                      </div>
                      <div className="upcomming-match-details">
                        <Link to={"/match/" + upcomingMatch.match_id} className="upcomming-match-details-link">
                          <span className="upcomming-match-tournament">{upcomingMatch.tournament}</span>
                          <span className="upcomming-match-teams">{upcomingMatch.first_team_name} vs {upcomingMatch.second_team_name}</span>
                          <span className="upcomming-match-day-time">{formatedDate(upcomingMatch.match_date, "dddd hh:mm A", true)}</span>
                        </Link>
                      </div>
                    </div>
                    <span className="upcomming-match-bet-lists">
                      {upcomingMatch.bet &&
                        <span className="single-upcomming-match-bet">
                          <span className="single-match-result-accordion-header">
                            <span className="status">{upcomingMatch?.bet?.bet_title}</span>
                          </span>
                          {upcomingMatch.bet.bet_events && upcomingMatch.bet.bet_events.length > 0 &&
                            <BetEvent betEvents={upcomingMatch.bet.bet_events} betStatus={upcomingMatch.bet.bet_status} />
                          }
                        </span>
                      }
                    </span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    }
  </>)
}

export default UpcomingMatches;
