import React, { useState } from 'react';
import { faUser, faEye, faCoins, faKey, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import WalletService from '../../../services/api/wallet.service';
import { setMessage } from '../../../redux/slices/message';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';
import MessageBox from '../../../components/common/message/MessageBox';
import AlertDismissible from '../../../components/common/message/AlertDismissible';
import { setUpdatedCoinsChange } from '../../../redux/slices/common';


const CoinTransfer = ({ setMessage, setUpdatedCoinsChange }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Your request has been created.");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append('username', data.username ? data.username : '');
    formData.append('transferring_coin_number', data.transferring_coin_number ? data.transferring_coin_number : '');
    formData.append('password', data.password ? data.password : '');
    setIsSubmitting(true);

    WalletService.coinTransfer(formData).then(
      (data) => {
        if (data) {
          // console.log('coinTransfer', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data);
          setUpdatedCoinsChange(1000);
          // setMessage({
          //   type: "success",
          //   title: data?.message,
          // });
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
        return Promise.reject();
      }
    );
  };

  return (<>
    <div className="tab-pane" id="nav-coin-transfer" role="tabpanel">
      <div className="admin-content-header-new">Coin Transfer</div>
      <div className="admin-content-scroll-wrapper">
        {isSubmitSuccess ?
          <MessageBox
            message={successMessage}
          />
          :
          <>
            <div className="row admin-profile-update-form">
              <div className="col-lg-6">
                <AlertDismissible
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-group">
                    <label htmlFor="adf-transfer-username">Username*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <input type="text" className="form-control" id="adf-transfer-username" placeholder="Username"
                        {...register("username", {
                          required: "Username required",
                        })}
                      />
                    </div>
                    {errors.username && <span className="input-validation">{errors.username.message}</span>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="adf-transfer-coin">Numbers of Coin*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faCoins} />
                      </span>
                      <input type="number" min="0" className="form-control" id="adf-transfer-coin" placeholder="Numbers of Coin"
                        {...register("transferring_coin_number", {
                          required: "Coin required",
                          min: {
                            value: 0,
                            message: "Coin must be greater than 0"
                          },
                        })}
                      />
                    </div>
                    {errors.transferring_coin_number && <span className="input-validation">{errors.transferring_coin_number.message}</span>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="adf-current-password-for-club">Current Password*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <input
                        className="form-control"
                        type={passwordMode.password}
                        placeholder="Current Password"
                        {...register("password", {
                          required: "Password required",
                        })}
                      />
                      <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                        <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
                      </button>
                    </div>
                    {errors.password && <span className="input-validation" style={{ right: "40px" }}>{errors.password.message}</span>}
                  </div>

                  <div className="row gx-0">
                    <div className="col-lg-8">
                      <button type="submit" className="admin-profile-update-form-submit" name="admin-coin-transfer" disabled={isSubmitting}>Transfer</button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
            {isSubmitting &&
              <LoadingSpinner overlay={true} />
            }
          </>
        }
      </div>
    </div>
  </>)
}
export default connect(null, { setMessage, setUpdatedCoinsChange })(CoinTransfer);