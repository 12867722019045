import axios from "axios";
import { apiPrefix, apiUrl } from "../../config/config";

class AuthService {

    static async getCountryList() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/countries")
            .then((response) => {
                return response.data.data;
            });
    }

    static async getClubList() {
        return await axios
            .get(apiUrl + "/" + apiPrefix + "/clubs")
            .then((response) => {
                return response.data.data;
            });
    }

    static async register(formData) {
        return await axios
            .post(apiUrl + "/" + apiPrefix + "/signup", formData)
            .then((response) => {
                // console.log(response);
                return response?.data?.data;
            });
    }

    static async signIn(formData) {
        return await axios
            .post(apiUrl + "/oauth/token", formData)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }
}

export default AuthService;