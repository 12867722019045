import { createSlice } from '@reduxjs/toolkit';
import { getRandomInt } from '../../helper/math';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    betSlipShow: false,
    mobileMenuShow: false,
    updatedCoinsChange: null,
  },
  reducers: {
    switchBetSlipMode: (state, action) => {
      state.betSlipShow = action.payload
    },
    switchMobileMenuMode: (state, action) => {
      state.mobileMenuShow = action.payload
    },
    setUpdatedCoinsChange: (state, action) => {
      state.updatedCoinsChange = getRandomInt(action.payload)
    },
  },
});

export const { switchBetSlipMode, switchMobileMenuMode, setUpdatedCoinsChange } = commonSlice.actions;

export default commonSlice.reducer;
