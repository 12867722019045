import React, { useState } from 'react';
import { faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import UserService from '../../../services/api/user.service';
import { setMessage } from '../../../redux/slices/message';
import FormButton from '../../../components/common/button/FormButton';
import MessageBox from '../../../components/common/message/MessageBox';
import AlertDismissible from '../../../components/common/message/AlertDismissible';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';

const UpdatePassword = ({ setMessage }) => {

  const [passwordMode, setPasswordMode] = useState({
    current_password: "password",
    password: "password",
    password_confirm: "password",
  });

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Your request has been created.");
  const [errorMessage, setErrorMessage] = useState("");
  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] == "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append('current_password', data.current_password ? data.current_password : '');
    formData.append('password', data.password ? data.password : '');
    formData.append('password_confirm', data.password_confirm ? data.password_confirm : '');
    setIsSubmitting(true);
    UserService.updatePassword(formData).then(
      (data) => {
        if (data) {
          // console.log('updatePassword', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data?.message);
          // setMessage({
          //   type: "success",
          //   title: data?.message,
          // });
          reset();
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
        return Promise.reject();
      }
    );
  };

  return (<>
    <div className="tab-pane" id="nav-edit-profile" role="tabpanel">
      <div className="admin-content-header-new">Update Profile</div>
      <div className="admin-content-scroll-wrapper">
        {isSubmitSuccess ?
          <MessageBox
            message={successMessage}
          />
          :
          <>
            <div className="row admin-profile-update-form">

              <div className="col-lg-6">
                <AlertDismissible
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-group">
                    <label htmlFor="adf-current-password-for-club">Current Password*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <input
                        className="form-control"
                        type={passwordMode.current_password}
                        placeholder="Current Password"
                        {...register("current_password", {
                          required: "Password required",
                        })}
                      />
                      <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("current_password")}>
                        <FontAwesomeIcon icon={passwordMode.current_password == "password" ? faEye : faEyeSlash} />
                      </button>
                    </div>
                    {errors.current_password && <span className="input-validation">{errors.current_password.message}</span>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="adf-current-password-for-club">New Password*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <input
                        className="form-control"
                        type={passwordMode.password}
                        placeholder="New Password"
                        {...register("password", {
                          required: "Password required",
                          minLength: {
                            value: 8,
                            message: "Minimum 8 characters",
                          }
                        })}
                      />
                      <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password")}>
                        <FontAwesomeIcon icon={passwordMode.password == "password" ? faEye : faEyeSlash} />
                      </button>
                    </div>
                    {errors.password && <span className="input-validation">{errors.password.message}</span>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="adf-current-password-for-club">Confirm Password*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <input
                        className="form-control"
                        type={passwordMode.password_confirm}
                        placeholder="New Password"
                        {...register("password_confirm", {
                          required: "Password required",
                          validate: (value) => value === watch('password') || "Password not match"
                        })}
                      />
                      <button type="button" className="field-icon toggle-password bg-transparent border-0 d-flex align-items-center p-0" onClick={() => handlePasswordMode("password_confirm")}>
                        <FontAwesomeIcon icon={passwordMode.password_confirm == "password" ? faEye : faEyeSlash} />
                      </button>
                    </div>
                    {errors.password_confirm && <span className="input-validation" style={{ right: "40px" }}>{errors.password_confirm.message}</span>}
                  </div>


                  <div className="row gx-0">
                    <div className="col-lg-8">
                      <FormButton
                        type="submit"
                        text="Update Password"
                        className="admin-profile-update-form-submit d-inline-flex align-items-center justify-content-center"
                        name="admin-update-password"
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  </div>
                </form>
              </div>


            </div>
            {isSubmitting &&
              <LoadingSpinner overlay={true} />
            }
          </>
        }
      </div>
    </div>
  </>)
}
export default connect(null, { setMessage })(UpdatePassword);