import { faCaretDown, faCaretUp, faLock, faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWindowWidth } from '../../../helper/browserWindow';
import { addBet } from '../../../redux/slices/bet';
import { switchBetSlipMode } from '../../../redux/slices/common';
import MatchService from '../../../services/api/match.service';

const BetEvent = ({ betEvents, betStatus }) => {
  // console.log("betEvents", betEvents);

  const dispatch = useDispatch();
  const browserWidth = useWindowWidth();
  const [betEventsData, setBetEventsData] = useState(betEvents);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setBetEventsData(betEvents);
    }

    return () => {
      mounted = false;
    }
  }, [betEvents]);

  const handleBetEvent = (id) => {
    // console.log("id", id);
    if (betStatus == 1) {

      MatchService.getBetDetailsById(id).then(
        (data) => {
          if (data) {
            // console.log('getLiveMatchList', data);
            dispatch(addBet(data));
            dispatch(switchBetSlipMode(true));
            if (browserWidth > 992) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error);
          return Promise.reject();
        }
      );
    }
  }

  return (<>
    {betEventsData && betEventsData.length > 0 &&
      <div className="single-match-result-accordion-body">
        <div className="row align-items-center justify-content-start gx-1">
          {betEventsData.map((betEvent, index) => {
            return (
              <div className="col col-lg-4" key={index}>
                <button className="single-match-result-box w-100 text-white" title={betEvent.bet_event_title} onClick={() => handleBetEvent(betEvent.id)}>
                  <div className="match-name">{betEvent.bet_event_title}</div>
                  {betStatus == 1 ?
                    <div className="match-point">
                      {betEvent.indicator && betEvent.indicator == "up" &&
                        <span className="indicator up me-2">
                          <FontAwesomeIcon icon={faLongArrowAltUp} />
                        </span>
                      }
                      {betEvent.indicator && betEvent.indicator == "down" &&
                        <span className="indicator down me-2">
                          <FontAwesomeIcon icon={faLongArrowAltDown} />
                        </span>
                      }
                      <span className="ratio-value">{betEvent.bet_ratio.toFixed(2)}</span>
                    </div>
                    :
                    <span className="lock me-3">
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                  }
                </button>
              </div>
            )
          })}
        </div>
      </div>
    }
  </>)
}
export default BetEvent;