import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const LoginLayout = ({ children, refreshToken }) => {

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshToken) {
        navigate('/');
      }
    }
    return () => {

    }
  }, [refreshToken]);

  return (<>
    <div className={`login-layout`}>
      {children}
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  refreshToken: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, null)(LoginLayout);