import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pagination = ({ currentPage, lastPage, handlePage }) => {
    // console.log("lastPage", lastPage);
    // console.log("currentPage", currentPage);
    return (<>
        <div className="row pagination-row">
            <div className="col-lg-12">
                <div className="pagination d-flex justify-content-end">
                    <ul className="d-flex">
                        {currentPage != 1 &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage - 1)} >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            </li>
                        }
                        {currentPage > 2 &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage - 2)} >{currentPage - 2}</button>
                            </li>
                        }
                        {currentPage > 1 &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage - 1)} >{currentPage - 1}</button>
                            </li>
                        }
                        <li className="active">
                            <button type="button" onClick={() => handlePage(currentPage)} >{currentPage}</button>
                        </li>
                        {(currentPage + 1) < lastPage &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage + 1)} >{currentPage + 1}</button>
                            </li>
                        }
                        {(currentPage + 2) < lastPage &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage + 2)} >{currentPage + 2}</button>
                            </li>
                        }
                        {(currentPage + 1) <= lastPage &&
                            <li>
                                <button type="button" onClick={() => handlePage(currentPage + 1)} >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    </>)
}

export default Pagination;
