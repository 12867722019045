import React, { useState } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { setMessage } from '../../../redux/slices/message';
import UserService from '../../../services/api/user.service';
import { useNavigate } from 'react-router-dom';
import FormButton from '../../../components/common/button/FormButton';
import MessageBox from '../../../components/common/message/MessageBox';
import AlertDismissible from '../../../components/common/message/AlertDismissible';
import LoadingSpinner from '../../../components/common/loader/LoadingSpinner';

const SubmitComplain = ({ setMessage }) => {

  const navigate = useNavigate();

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("Your request has been created.");
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append('qtitle', data.qtitle ? data.qtitle : '');
    formData.append('qdescription', data.qdescription ? data.qdescription : '');
    setIsSubmitting(true);
    UserService.submitComplain(formData).then(
      (data) => {
        if (data) {
          // console.log('submitComplain', data);
          setIsSubmitSuccess(true);
          setSuccessMessage(data?.message);
          reset();
          // setMessage({
          //   type: "success",
          //   title: data?.message,
          // });
          // navigate("/support/complain-history");
        }
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2100);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.resolve();
      },
      (error) => {
        // console.log(error?.response?.data?.errors?.message);
        // setMessage({
        //   type: "error",
        //   title: error?.response?.data?.errors?.message,
        // });
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.errors?.message);
          setIsSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 1000);
        return Promise.reject();
      }
    );
  };


  return (<>
    <div className="tab-pane" id="nav-submit-complain" role="tabpanel">
      <div className="admin-content-header-new">Submit Complain</div>
      <div className="admin-content-scroll-wrapper">
        {isSubmitSuccess ?
          <MessageBox
            message={successMessage}
          />
          :
          <>
            <div className="row admin-profile-update-form">

              <div className="col-lg-6">
                <AlertDismissible
                  message={errorMessage}
                  setErrorMessage={setErrorMessage}
                />
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-group">
                    <label htmlFor="adf-complain-subject">Subject*</label>
                    <div className="form-group-input-wrapper">
                      <span className="input-group-icon">
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <input type="text" className="form-control" id="adf-complain-subject" placeholder="Enter your complain subject"
                        {...register("qtitle", {
                          required: "Subject required",
                        })}
                      />
                    </div>
                    {errors.qtitle && <span className="input-validation">{errors.qtitle.message}</span>}
                  </div>

                  <div className="form-group">
                    <label htmlFor="adf-complain-description">Complain description*</label>
                    <div className="form-group-input-wrapper align-items-start">
                      <textarea className="form-control form-control-100" id="adf-complain-description" placeholder="Complain description"
                        {...register("qdescription", {
                          required: "Description required",
                        })}
                      ></textarea>
                    </div>
                    {errors.qdescription && <span className="input-validation">{errors.qdescription.message}</span>}
                  </div>

                  <div className="row gx-0">
                    <div className="col-lg-8">
                      <FormButton
                        type="submit"
                        text="Submit"
                        className="admin-profile-update-form-submit d-inline-flex align-items-center justify-content-center"
                        name="admin-complain-submit"
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  </div>
                </form>

              </div>
            </div>
            {isSubmitting &&
              <LoadingSpinner overlay={true} />
            }
          </>
        }
      </div>
    </div>
  </>)
}
export default connect(null, { setMessage })(SubmitComplain);